import { FRONTEMPLOYER_ANALYTICS_GET_ORDERS_COUNT } from "actions/types";

const initialState = {
  analyticsHeadCount: {
    currentOrdersCount: 0,
    lastOrdersCount: 0,
    ordersPercent: 0,
    currentDrugTestsCount: 0,
    lastDrugTestsCount: 0,
    drugTestsPercent: 0,
    currentPatientsCount: 0,
    lastPatientsCount: 0,
    patientsPercent: 0,
    currentReportsCount: 0,
    lastReportsCount: 0,
    reportsPercent: 0,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_ANALYTICS_GET_ORDERS_COUNT:
      return {
        ...initialState,
        analyticsHeadCount: payload,
      };

    default:
      return state;
  }
}
