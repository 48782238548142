import {
  FRONTEMPLOYER_EMPLOYEE_LIST_LOADING,
  FRONTEMPLOYER_EMPLOYEE_RESET,
  FRONTEMPLOYER_EMPLOYEE_LOAD_PAGE,
  FRONTEMPLOYER_EMPLOYEE_ERROR,
  FRONTEMPLOYER_EMPLOYEE_CHANGE_STATUS,
  FRONTEMPLOYER_EMPLOYEE_CREATED,
  FRONTEMPLOYER_EMPLOYEE_LOADING_ON_SUBMIT,
  FRONTEMPLOYER_EMPLOYEE_GET_BY_ID,
  FRONTEMPLOYER_EMPLOYEE_UPDATED,
  FRONTEMPLOYER_EMPLOYEE_LIST_UPDATED,
  FRONTEMPLOYER_EMPLOYEE_QUALIFICATION_DELETE,
  FRONTEMPLOYER_EMPLOYEE_DELETE,
  FRONTEMPLOYER_EMPLOYEE_UPLOADED,
  FRONTEMPLOYER_EMPLOYEE_SEARCH_PARAMATERS_UPDATE,
  FRONTEMPLOYER_EMPLOYEE_INSURANCE_ON_SUBMIT,
  FRONTEMPLOYER_EMPLOYEE_INSURANCE_UPDATED,
  FRONTEMPLOYER_EMPLOYEE_EMPLOYER_INFO
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  employeeList: {
    page: 1,
    data: [],
    count: 0
  },
  currentEmployee: null,
  loadingEmployeeList: true,
  loadingEmployee: true,
  loadingUploadEmployee: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
  loadingInsuranceForm: false,
  orgShortInfo: {}
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_EMPLOYEE_RESET:
      return {
        ...initialState,
        loadingEmployee: state.loadingEmployee,
        loadingEmployeeList: state.loadingEmployeeList,
        loadingListData: state.loadingListData,
      }
    case FRONTEMPLOYER_EMPLOYEE_LOAD_PAGE:
      return {
        ...state,
        loadingEmployee: false,
        loadingListData: false,
        loadingUploadEmployee: false
      };
    case FRONTEMPLOYER_EMPLOYEE_CREATED:
      return {
        ...state,
        sortingParams: initialState.sortingParams,
        loadingEmployee: false
      };
    case FRONTEMPLOYER_EMPLOYEE_UPDATED:
      return {
        ...state,
        // currentEmployee: null,
        sortingParams: initialState.sortingParams,
        loadingEmployee: false,
      };
    case FRONTEMPLOYER_EMPLOYEE_DELETE:
      const currentCount = state.employeeList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.employeeList.page);
      var remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        employeeList: {
          data: state.employeeList.data.filter(employee =>
            employee._id !== payload
          ),
          count: currentCount - 1,
          page: currentPage <= remainingPages ? currentPage.toString() : remainingPages.toString(),
        },
        performDelete: true,
        loadingListData: false,
        // sortingParams: initialState.sortingParams,

      };
    case FRONTEMPLOYER_EMPLOYEE_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployee: false,
        loadingEmployeeList: false,
        loadingListData: false,
        loadingUploadEmployee: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_EMPLOYEE_GET_BY_ID:
      return {
        ...state,
        currentEmployee: payload,
        loadingEmployee: false,
      };
    case FRONTEMPLOYER_EMPLOYEE_QUALIFICATION_DELETE:
      return {
        ...state,
        currentEmployee: {
          ...state.currentEmployee,
          qualifications: state.currentEmployee.qualifications.filter(cert =>
            cert._id !== payload
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingEmployee: false,
      }
    case FRONTEMPLOYER_EMPLOYEE_LIST_UPDATED:
      return {
        ...state,
        employeeList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord
        },
        loadingEmployeeList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_EMPLOYEE_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };
    case FRONTEMPLOYER_EMPLOYEE_CHANGE_STATUS:
      return {
        ...state,
        currentEmployee: {
          ...state.currentEmployee,
          status: payload.status
        },
        loadingListData: false,
      };
    case FRONTEMPLOYER_EMPLOYEE_LIST_LOADING:
      return {
        ...state,
        loadingListData: true
      };
    case FRONTEMPLOYER_EMPLOYEE_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingEmployee: true,
        loadingUploadEmployee: true
      };
    case FRONTEMPLOYER_EMPLOYEE_UPLOADED:
      return {
        ...state,
        loadingEmployee: false,
        loadingUploadEmployee: false
      }
    case FRONTEMPLOYER_EMPLOYEE_INSURANCE_ON_SUBMIT:
      return {
        ...state,
        loadingInsuranceForm: true
      };
    case FRONTEMPLOYER_EMPLOYEE_INSURANCE_UPDATED:
      return {
        ...state,
        loadingInsuranceForm: false,
        currentEmployee: {
          ...state.currentEmployee,
          paymentBy: "2",
          insurance: payload,
        },
      };
    case FRONTEMPLOYER_EMPLOYEE_EMPLOYER_INFO:
      return {
        ...state,
        orgShortInfo: payload
      };
    default:
      return state;
  }
}
