import {
  FRONTSTAFF_EMPLOYEE_RESET,
  FRONTSTAFF_EMPLOYEE_LOAD_PAGE,
  FRONTSTAFF_EMPLOYEE_ERROR,
  FRONTSTAFF_EMPLOYEE_LOADING_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_GET_BY_ID,
  FRONTSTAFF_EMPLOYEE_UPDATED,
  FRONTSTAFF_EMPLOYEE_INSURANCE_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_INSURANCE_UPDATED,
  FRONTSTAFF_EMPLOYEE_ORDER,
  FRONTSTAFF_EMPLOYEE_BARCODE_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_BARCODE_SAVED,
  FRONTSTAFF_EMPLOYEE_BARCODE_ERROR,
  FRONTSTAFF_EMPLOYEE_HEALTH_LOADING,
  FRONTSTAFF_EMPLOYEE_HEALTH_QUESTIONS,
  FRONTSTAFF_EMPLOYEE_HEALTH_ERROR,
  FRONTSTAFF_EMPLOYEE_HEALTH_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_HEALTH_SUBMITED,
  FRONTSTAFF_EMPLOYEE_CONSENT_LOADING,
  FRONTSTAFF_EMPLOYEE_CONSENT_ERROR,
  FRONTSTAFF_EMPLOYEE_CONSENT_TEXT,
  FRONTSTAFF_EMPLOYEE_CONSENT_ON_SUBMIT,
  FRONTSTAFF_EMPLOYEE_CONSENT_SUBMITED,
  FRONTSTAFF_EMPLOYEE_LOADING_DONE
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  employeeList: {
    page: 1,
    data: [],
    count: 0
  },
  currentEmployee: null,
  loadingEmployeeList: true,
  loadingEmployee: true,
  loadingUploadEmployee: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
  loadingInsuranceForm: false,
  orgShortInfo: {},
  currentOrder: null,
  barcodeSaving: false,
  healthLoading: false,
  healthCheckupQuestoins: [],
  healthCheckupFormLoading: false,
  consentTextLoading: false,
  consentFormText: {},
  consentFormLoading: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTSTAFF_EMPLOYEE_RESET:
      return {
        ...initialState,
        loadingEmployee: state.loadingEmployee,
        loadingEmployeeList: state.loadingEmployeeList,
        loadingListData: state.loadingListData,
      }
    case FRONTSTAFF_EMPLOYEE_LOAD_PAGE:
      return {
        ...state,
        loadingEmployee: false,
        loadingListData: false,
        loadingUploadEmployee: false
      };
    case FRONTSTAFF_EMPLOYEE_UPDATED:
      return {
        ...state,
        // currentEmployee: null,
        sortingParams: initialState.sortingParams,
        loadingEmployee: false,
      };
    case FRONTSTAFF_EMPLOYEE_ERROR:
      return {
        ...state,
        error: payload,
        loadingEmployee: false,
        loadingEmployeeList: false,
        loadingListData: false,
        loadingUploadEmployee: false,
        performDelete: false,
      };
    case FRONTSTAFF_EMPLOYEE_GET_BY_ID:
      return {
        ...state,
        currentEmployee: payload,
        loadingEmployee: false,
      };

    case FRONTSTAFF_EMPLOYEE_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingEmployee: true,
        loadingUploadEmployee: true
      };
    case FRONTSTAFF_EMPLOYEE_INSURANCE_ON_SUBMIT:
      return {
        ...state,
        loadingInsuranceForm: true
      };
    case FRONTSTAFF_EMPLOYEE_INSURANCE_UPDATED:
      return {
        ...state,
        loadingInsuranceForm: false,
        currentEmployee: {
          ...state.currentEmployee,
          paymentBy: "2",
          insurance: payload,
        },
      };
    case FRONTSTAFF_EMPLOYEE_ORDER:
      return {
        ...state,
        currentOrder: payload
      }
    case FRONTSTAFF_EMPLOYEE_BARCODE_ON_SUBMIT:
      return {
        ...state,
        barcodeSaving: true
      }
    case FRONTSTAFF_EMPLOYEE_BARCODE_SAVED:
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          patientInfo: {
            ...state.currentOrder.patientInfo,
            barcode: [payload]
          }
        },
        barcodeSaving: false
      }
    case FRONTSTAFF_EMPLOYEE_BARCODE_ERROR:
      return {
        ...state,
        barcodeSaving: false
      }
    case FRONTSTAFF_EMPLOYEE_HEALTH_LOADING:
      return {
        ...state,
        healthLoading: true
      }
    case FRONTSTAFF_EMPLOYEE_HEALTH_QUESTIONS:
      return {
        ...state,
        healthCheckupQuestoins: payload,
        healthLoading: false
      }
    case FRONTSTAFF_EMPLOYEE_HEALTH_ERROR:
      return {
        ...state,
        healthLoading: false
      }
    case FRONTSTAFF_EMPLOYEE_HEALTH_ON_SUBMIT:
      return {
        ...state,
        healthCheckupFormLoading: true,
      }
    case FRONTSTAFF_EMPLOYEE_HEALTH_SUBMITED:
      return {
        ...state,
        healthCheckupFormLoading: false,
        currentOrder: {
          ...state.currentOrder,
          patientInfo: {
            ...state.currentOrder.patientInfo,
            screeningForm: payload.map(each => {
              return { question: each._id, response: each.response }
            })
          }
        }
      }
    case FRONTSTAFF_EMPLOYEE_CONSENT_LOADING:
      return {
        ...state,
        consentTextLoading: true,
      }
    case FRONTSTAFF_EMPLOYEE_CONSENT_ERROR:
      return {
        ...state,
        consentTextLoading: false,
      }
    case FRONTSTAFF_EMPLOYEE_CONSENT_TEXT:
      return {
        ...state,
        consentFormText: payload,
        consentTextLoading: false,
      }
    case FRONTSTAFF_EMPLOYEE_CONSENT_ON_SUBMIT:
      return {
        ...state,
        consentFormLoading: true
      }
    case FRONTSTAFF_EMPLOYEE_CONSENT_SUBMITED:
      return {
        ...state,
        consentFormLoading: false,
        currentOrder: {
          ...state.currentOrder,
          patientInfo: {
            ...state.currentOrder.patientInfo,
            consentForm: true
          }
        }
      }
    case FRONTSTAFF_EMPLOYEE_LOADING_DONE:
      return {
        ...state,
        loadingEmployee: false,
        loadingUploadEmployee: false
      }
    default:
      return state;
  }
}
