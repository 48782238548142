import {
  FRONTLAB_TESTORDER_LIST_LOADING,
  FRONTLAB_TESTORDER_RESET,
  FRONTLAB_TESTORDER_LOAD_PAGE,
  FRONTLAB_TESTORDER_ERROR,
  FRONTLAB_TESTORDER_CHANGE_STATUS,
  FRONTLAB_TESTORDER_CREATED,
  FRONTLAB_TESTORDER_LOADING_ON_SUBMIT,
  FRONTLAB_TESTORDER_GET_BY_ID,
  FRONTLAB_TESTORDER_UPDATED,
  FRONTLAB_TESTORDER_LIST_UPDATED,
  FRONTLAB_TESTORDER_QUALIFICATION_DELETE,
  FRONTLAB_TESTORDER_DELETE,
  // FRONTLAB_TESTORDER_UPLOADED,
  FRONTLAB_TESTORDER_SEARCH_PARAMATERS_UPDATE,
  FRONTLAB_TESTORDER_RESULT_UPLOADED,
  FRONTLAB_TESTORDER_PATIENT_DATA,
  FRONTLAB_TESTORDER_RESULT_UPLOADED_SINGLE,
  FRONTLAB_TESTORDER_RESULT_REMOVED,
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  testOrderList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentTestOrder: null,
  loadingTestOrderList: true,
  loadingTestOrder: true,
  // loadingUploadTestOrder: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
  resultUploaded: false,
  recentUploadResult: null,
  orderPatientDataForCSV: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTLAB_TESTORDER_RESET:
      return {
        ...initialState,
        loadingTestOrder: state.loadingTestOrder,
        loadingTestOrderList: state.loadingTestOrderList,
        loadingListData: state.loadingListData,
      };
    case FRONTLAB_TESTORDER_LOAD_PAGE:
      return {
        ...state,
        loadingTestOrder: false,
        loadingListData: false,
        // loadingUploadTestOrder: false
      };
    case FRONTLAB_TESTORDER_CREATED:
      return {
        ...state,
        loadingTestOrder: false,
      };
    case FRONTLAB_TESTORDER_UPDATED:
      return {
        ...state,
        currentTestOrder: null,
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTLAB_TESTORDER_DELETE:
      const currentCount = state.testOrderList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.testOrderList.page);
      var remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        testOrderList: {
          data: state.testOrderList.data.filter(
            (employee) => employee._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        performDelete: true,
        loadingListData: false,
      };
    case FRONTLAB_TESTORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingTestOrder: false,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTLAB_TESTORDER_GET_BY_ID:
      return {
        ...state,
        currentTestOrder: payload,
        loadingTestOrder: false,
      };
    case FRONTLAB_TESTORDER_QUALIFICATION_DELETE:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          qualifications: state.currentTestOrder.qualifications.filter(
            (cert) => cert._id !== payload
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTLAB_TESTORDER_LIST_UPDATED:
      return {
        ...state,
        testOrderList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTLAB_TESTORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case FRONTLAB_TESTORDER_CHANGE_STATUS:
      return {
        ...state,
        testOrderList: {
          ...state.testOrderList,
          data: state.testOrderList.data.map((employee) =>
            employee._id === payload._id
              ? { ...employee, status: payload.status }
              : employee
          ),
        },
        loadingListData: false,
      };
    case FRONTLAB_TESTORDER_LIST_LOADING:
      return {
        ...state,
        loadingListData: true,
      };
    case FRONTLAB_TESTORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingTestOrder: true,
        // loadingUploadTestOrder: true
      };
    case FRONTLAB_TESTORDER_RESULT_UPLOADED:
      return {
        ...state,
        resultUploaded: true,
        recentUploadResult: payload,
      };
    case FRONTLAB_TESTORDER_PATIENT_DATA:
      return {
        ...state,
        orderPatientDataForCSV: payload,
      };

    case FRONTLAB_TESTORDER_RESULT_UPLOADED_SINGLE:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          order_items: state.currentTestOrder.order_items.map((eachItem) =>
            eachItem._id === payload.order_item_id
              ? {
                  ...eachItem,
                  results:
                    eachItem.results && eachItem.results.length
                      ? [...eachItem.results, payload.result]
                      : [payload.result],
                }
              : eachItem
          ),
        },
      };
    case FRONTLAB_TESTORDER_RESULT_REMOVED:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          employees: state.currentTestOrder.employees.map((eachEmp) =>
            eachEmp._id === payload.patient_id
              ? {
                  ...eachEmp,
                  result: undefined,
                  resultFile: undefined,
                }
              : eachEmp
          ),
        },
      };
    default:
      return state;
  }
}
