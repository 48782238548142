import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
const InActive = () => {
  return (
  <>
    <Helmet>
      <title>Link Disabled - {process.env.REACT_APP_APP_NAME}</title>
    </Helmet>
    <div className="center-text-inActivelink">
    <h1 className="x-large text-primary">
      <i className="fa fa-exclamation-triangle"></i> <div className="center-text">Link Disabled</div>
    </h1>
    <p className="large">Sorry, this link is no more active.</p>
    </div>
  </>
  );
};

export default InActive;
