import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Img from "react-image";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Container,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Helmet } from "react-helmet";

import {
  verifyPatientInformation,
  getEmployeeById,
} from "actions/front/Employer/Employee";
import {
  setErrors,
  removeErrors,
  loadPage,
  notFound,
} from "actions/front/Employer/EmployerProfile";
import { loginRedirect, loadAuthPage } from "actions/auth";
import { removeAlert } from "actions/alert";

import { sfold, checkScripts } from "utils/sFold";
import GMaps from "utils/GMaps";
import extractNumber from "utils/extractNumber";
import { validateForm } from "utils/validation";
import { uniqueString } from "utils/nodeHelper";
import getAge from "utils/getAge";

import InsuranceDetails from "../../Front/Employer/Employee/InsuranceDetails";
import VaccineInformation from "../../Front/Employer/Employee/VaccineInformation";
import InActiveLink from "views/InActiveLink";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import ConsentForm from "./ConsentForm";
import LogoSvg from "assets/iLabDx-Logo.png";
import Spinner from "views/Spinner";

import * as Constants from "constants/index";

const EmployerSignUp = ({
  verifyPatientInformation,
  currentEmployee,
  getEmployeeById,
  notFound,
  errorList,
  loadPage,
  history,
  loading,
  removeAlert,
  setErrors,
  removeErrors,
  loadAuthPage,
  isLoginError,
  prePath,
  match,
}) => {
  const [testingType, setTestingType] = useState([]);

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;

  const initialState = {
    username: "",
    identification: "",
    user_id: "",
    fullName: "",
    gender: "",
    dob: "",
    isPregnant: false,
    isChild: false,
    parentsApproval: "no",
    email: "",
    minDate: today,
    ccode: "+1",
    phone: "",
    iso: "US",
    linkActive: false,
    paymentBy: "2",
    iute164_phone: "",
    line_1: "",
    line_2: "",
    a_city: {
      formatted_address: "",
      city_name: "",
      state: "",
      stateAbbr: "",
      country: "",
      short: "",
      location: {
        south: "",
        west: "",
        north: "",
        east: "",
      },
      place_id: "",
    },
    a_city_placeholder: "",
    zip_code: "",
    city_id: "",
    race: "",
    ethnicity: "",
    ssn: "",
    jobNumber: "",
    secondaryUsername: "",
    alternativePhoneCCode: "+1",
    alternativePhone: "",
    alternativePhoneISO: "US",
    alternativePhone_full: "",
    occupation: "",
    employedInHealthcare: "",
    insuranceCard: "",
    insuranceCard_path: "",
    p_insuranceId: "",
    p_insuranceName: "",
    p_state: "",
    p_group: "",
    p_insurancePhone: "",
    p_plan: "",
    p_insurancePhone_iute164: "",
    p_insurancePhone_ccode: "+1",
    p_nameOfInsured: "",
    p_relationToPatient: "",
    p_dob: "",
    s_insuranceId: "",
    s_insuranceName: "",
    s_state: "",
    s_group: "",
    s_insurancePhone: "",
    s_plan: "",
    s_insurancePhone_iute164: "",
    s_insurancePhone_ccode: "+1",
    s_nameOfInsured: "",
    s_relationToPatient: "",
    s_dob: "",

    // vaccine
    vaccineType: "",
    firstDoseDone: "no",
    firstDoseDate: "",
    secondDoseDone: "no",
    secondDoseDate: "",
    boosterDone: "no",
    boosterDate: "",

    signatureImgURL: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [onlyOnce, setOnce] = useState(true);
  const [formParsed, setFormParsed] = useState(false);

  const {
    fullName,
    email,
    ccode,
    iute164_phone,
    line_1,
    line_2,
    gender,
    dob,
    isChild,
    parentsApproval,
    minDate,
    zip_code,
    a_city_placeholder,
    username,
    race,
    ethnicity,
    ssn,
    jobNumber,
    secondaryUsername,
    alternativePhone_full,
    occupation,
    employedInHealthcare,
    linkActive,
    isPregnant,
    paymentBy,
  } = formData;

  //###################### change event for all inputs ######################
  const onChange = (e) => {
    switch (e.target.name) {
      case "isPregnant":
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        break;
      case "parentsApproval":
        if (e.target.checked) {
          setFormData({
            ...formData,
            [e.target.name]: "yes",
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: "no",
          });
        }
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onDateChange = (e, time) => {
    const age = getAge(e.target.value);
    setFormData({
      ...formData,
      dob: e.target.value,
      isChild: age < 18 ? true : false,
      parentsApproval: "no",
    });
  };

  //validating secondary username.
  let [timeout, setTimeOutVariable] = useState(null); //useState to prevent timeout get cleared every time app renders
  const onKeyUp = async (e) => {
    removeErrors();
    removeAlert();
    clearTimeout(timeout);
    const newUsername = e.target.value;
    const fieldName = e.target.name;
    // Make a new timeout set to go off in 1000ms (1 second)
    if (!newUsername) return;
    setTimeOutVariable(
      setTimeout(async () => {
        const errors = [];
        let regExp = new RegExp("^[A-Za-z0-9]{3,50}$");
        if (!newUsername.match(regExp)) {
          errors.push({
            param: fieldName,
            msg: "Username length must be of 3 to 50 characters and contain only alphanumeric characters.",
          });
          setErrors(errors);
        }
      }, 2000)
    );
  };

  const onPhoneChange = (number) => {
    const phone = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      phone: phone,
      iute164_phone: number || ccode,
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  const onAlternativePhoneChange = (number) => {
    const phone = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      alternativePhone: phone,
      alternativePhone_full: number || ccode,
    }));
  };

  const onAlternativeCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      alternativePhoneISO: code || "US",
      alternativePhoneCCode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  const handleSelect = (name, data) => {
    // console.log(name);
    setFormData({
      ...formData,
      [name]: data,
    });
  };

  const getEthnicityOptions = (
    <>
      {Constants.ethnicityOptions.map((option, index) => {
        return (
          <FormGroup key={index} check inline>
            {"  "}
            <Label check>
              <Input
                type="radio"
                name="ethnicity"
                value={option.value}
                onChange={(e) => onChange(e)}
                checked={!!ethnicity.match(option.regex)}
              />{" "}
              {option.label}{" "}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );

  const getEmployedInHealthcateOptions = (
    <>
      {["Yes", "No", "Unknown"].map((option, index) => {
        return (
          <FormGroup check inline>
            {"  "}
            <Label check>
              <Input
                type="radio"
                name="employedInHealthcare"
                value={option}
                onChange={(e) => onChange(e)}
                checked={employedInHealthcare === option}
              />{" "}
              {option}{" "}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );

  const [formUpdated, setFormUpdated] = useState(false);

  //################## For css ##################
  const [loadingCSS, setLoadingCSS] = useState(true);

  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;
  //Number of employee list
  const InjectCSS = async () => {
    try {
      const cssArray = [
        "/assets/lab/plugins/sidemenu/sidemenu.css",
        "/assets/lab/plugins/bootstrap/css/bootstrap.min.css",
        "/assets/lab/css/style.css",
        "/assets/lab/css/admin-custom.css",
        "/assets/lab/css/custom.css",
        "/assets/lab/css/icons.css",
        "/assets/lab/color-skins/color10.css",
      ];
      stylesNeedToBeLoaded = cssArray.length;
      if (!checkScripts("lab_panel_css"))
        cssArray.forEach((url) => {
          addStyleScript(url, "css", "lab_panel_css");
        });
      else setLoadingCSS(false);
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    let tag,
      tag_id = sfold(package_name);
    switch (type) {
      case "css":
        tag = document.createElement("link");
        tag.rel = "stylesheet";
        tag.href = url;
        tag.async = true;
        tag.dataset.id = tag_id;
        tag.onload = () => styleLoaded(tag_id);
        break;
    }
    document.head.appendChild(tag);
  };
  //##########################UseMemo, useEffect ########################
  useMemo(() => {
    if (onlyOnce) {
      InjectCSS();
      loadPage();
      removeAlert();
      loadAuthPage(history, prePath);
      getEmployeeById(match.params.user_id, "Uid").then((res) => {
        !res && notFound(history);
      });
      setOnce(false);
    }
  });

  useEffect(() => {
    const asyncParams = async () => {
      try {
        if (Boolean(currentEmployee)) {
          const {
            employer,
            address,
            user,
            isChild,
            parentsApproval,
            paymentBy,
          } = currentEmployee;

          const line_1 = address ? address.line_1 : "";
          const line_2 = address ? address.line_2 : "";
          const city =
            address && address.city ? address.city : initialState.a_city;
          const zip_code = address ? address.zip_code : "";
          const {
            name,
            email,
            dob,
            gender = gender ? gender : "",
            ccode = ccode ? ccode : "+1",
            phone,
            iso,
            linkActive,
            isPregnant,
            username,
            secondaryUsername,
          } = user;
          const {
            formatted_address,
            state,
            stateAbbr,
            country,
            place_id,
            short,
            location,
          } = city || initialState.a_city;
          const city_name = city.city || initialState.a_city.city;
          const user_id = user._id;
          const a_city_placeholder = city_name
            ? formatted_address ??
              `${city_name}, ${stateAbbr ?? state}, ${country}`
            : "Please Enter Your City Name";
          const iute164_phone = phone ? ccode + phone : "";

          const {
            race,
            ethnicity,
            ssn,
            jobNumber,
            alternativePhone,
            alternativePhoneISO,
            alternativePhoneCCode,
            occupation,
            employedInHealthcare,
          } = currentEmployee;
          const alternativePhone_full = alternativePhone
            ? alternativePhoneCCode + alternativePhone
            : "";
          const selectedRace = Constants.raceOptions.filter((option) => {
            return race == option.value;
          });

          if (currentEmployee.employer && currentEmployee.employer.length) {
            let tType = [];
            currentEmployee.employer.forEach((org) => {
              tType = [...tType, ...org.testingType];
            });

            setTestingType(tType);
          }

          setFormData((form) => ({
            ...form,
            ...{
              line_1: line_1 || "",
              line_2: line_2 || "",
              a_city: {
                formatted_address,
                city_name,
                state,
                stateAbbr,
                country,
                place_id,
                short,
                location,
              },
              a_city_placeholder,
              zip_code: zip_code || "",
              user_id,
              dob: dob ? moment(dob).format("YYYY-MM-DD") : "",
              isPregnant,
              isChild,
              linkActive,
              parentsApproval,
              fullName: name,
              gender,
              email,
              iute164_phone,
              ccode,
              phone,
              iso,
              paymentBy: paymentBy ? paymentBy : 2,
              race: race
                ? selectedRace[0]
                : { value: "", label: "Select race" },
              ethnicity: ethnicity ? ethnicity : "",
              ssn: ssn ? ssn : "",
              jobNumber: jobNumber ? jobNumber : "",
              username,
              secondaryUsername,
              alternativePhone_full,
              alternativePhoneCCode,
              alternativePhone,
              alternativePhoneISO,
              occupation,
              employedInHealthcare,
            },
          }));
          setFormParsed(true);
        }
      } catch (err) {
        console.log(err);
      }
    };
    asyncParams();
  }, [currentEmployee]);
  //####################### change event for all inputs #########################

  //####################### Load city Option #########################
  const insuranceCommonFieilds = [
    "insuranceCardFront",
    "insuranceCardBack",
    "insuranceId",
    "insuranceName",
    "state",
    "group",
    "effectiveDate",
    "expirationDate",
    "insurancePhone",
    "insurancePhone_iute164",
    "insurancePhone_ccode",
    "plan",
    "nameOfInsured",
    "relationToPatient",
    "dob",
  ];

  //##### Wrapper for insuranceDetails ######
  const wrapInsuranceFields = (submitData) => {
    submitData.primaryInsurance = {};
    submitData.secondaryInsurance = {};

    insuranceCommonFieilds.forEach((field) => {
      if (submitData["p_" + field]) {
        submitData.primaryInsurance[field] = submitData["p_" + field];
        delete submitData["p_" + field];
      }
      if (submitData["s_" + field]) {
        submitData.secondaryInsurance[field] = submitData["s_" + field];
        delete submitData["s_" + field];
      }
    });
    return submitData;
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  //######################## Submit form #############################
  const onSubmit = (e) => {
    e.preventDefault();
    removeErrors();

    let validationRules = [
      {
        param: "fullName",
        msg: "Please provide the name",
      },
      {
        param: "memberPassword",
        msg: "Please provide password",
      },
      {
        param: "gender",
        msg: "Please provide a valid date of birth",
      },
      {
        param: "dob",
        msg: "Please provide a valid date of birth",
      },
      {
        param: "ssn",
        msg: "Please provide the SSN",
      },
      {
        param: "line_1",
        msg: "Please provide a address line 1",
      },
      {
        param: "a_city",
        value: "place_id",
        type: "object",
        msg: "Please select a valid city",
      },
      {
        param: "zip_code",
        msg: "Please provide a zip code",
      },
    ];

    if (testingType.includes("employment")) {
      // validationRules.push({
      //   param: "jobNumber",
      //   msg: "Please provide the Job #",
      // });
    }

    let error = validateForm(formData, validationRules);

    if (!error) error = [];

    if (formData.fullName) {
      const nameArray = formData.fullName.split(" ");
      if (nameArray.length < 2 || !nameArray[nameArray.length - 1].trim()) {
        error.push({
          param: "fullName",
          msg: "The last name is required.",
        });
      }
    }

    if (formData.dob && formData.isChild && formData.parentsApproval == "no") {
      error.push({
        param: "parentsApproval",
        msg: "Please ensure you have parents approval.",
      });
    }

    if (error.length) {
      setErrors(error);
      return;
    }

    let submitData = {
      uuid: match.params.user_id,
    };

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    submitData.race = submitData.race ? submitData.race.value : "";

    loadAuthPage(history);
    submitData = wrapInsuranceFields(submitData);

    setLoadingSubmit(true);
    verifyPatientInformation(submitData, match.params.user_id).then((res) => {
      setLoadingSubmit(false);
      if (res && res.status) {
        setFormUpdated(true);
      }
    });
  };

  const getPaymentByOptions = (
    <>
      {Constants.paymentByOptions.map((option, index) => {
        return (
          <FormGroup key={index} check inline>
            {"  "}
            <Label check>
              <Input
                type="radio"
                name="paymentBy"
                value={option.value}
                onChange={(e) => onChange(e)}
                checked={!!paymentBy.match(option.regex)}
                disabled={option.value === "1" || option.value === "3"}
              />{" "}
              {option.label}{" "}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );

  // console.log(history,match, loading);
  return loadingCSS || loading || !formParsed ? (
    <Spinner />
  ) : !linkActive ? (
    <InActiveLink />
  ) : (
    <>
      <Helmet>
        <title>
          Complete your information - {process.env.REACT_APP_APP_NAME}
        </title>
      </Helmet>
      <div className="page page-h">
        <div className="page-content z-index-10">
          <Container>
            <Row>
              <Col md="12">
                <div className="side-app">
                  <Row>
                    <Col lg="4" sm="12" className="offset-lg-4">
                      <div className="text-center w-100 d-block mb-4">
                        <Img alt="logo" src={LogoSvg} className="w-70" />
                      </div>
                    </Col>
                    <Col md="12">
                      <Row>
                        <Col lg="12">
                          {formUpdated ? (
                            <Alert />
                          ) : (
                            <Form onSubmit={(e) => onSubmit(e)}>
                              <Card>
                                <CardHeader className="justify-content-center">
                                  <h3 className="card-title">
                                    Complete Your Information
                                  </h3>
                                </CardHeader>
                                <CardFooter className="p-0"></CardFooter>

                                {!isLoginError ? (
                                  <Row>
                                    <Col>
                                      <Alert />
                                    </Col>
                                  </Row>
                                ) : null}

                                <Row>
                                  <Col lg="6" md="6" sm="12">
                                    <CardHeader>
                                      <h3 className="card-title">
                                        Personal Information
                                      </h3>
                                    </CardHeader>

                                    <CardBody>
                                      <FormGroup>
                                        <Label
                                          className="form-label"
                                          htmlFor="fullName"
                                        >
                                          Name *
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="fullName"
                                          name="fullName"
                                          maxLength="25"
                                          value={fullName}
                                          required
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onChange(e)}
                                          invalid={
                                            errorList.fullName ? true : false
                                          }
                                        />
                                        <Errors
                                          current_key="fullName"
                                          key="fullName"
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Label>Date of Birth *</Label>
                                        <Input
                                          type="Date"
                                          id="example-datepicker-primary"
                                          value={dob}
                                          max={minDate}
                                          required
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onDateChange(e)}
                                        />
                                        <Errors current_key="dob" key="dob" />

                                        {/* <FormText>Help</FormText> */}
                                      </FormGroup>

                                      {isChild && (
                                        <FormGroup>
                                          <Label style={{ marginLeft: "20px" }}>
                                            <Input
                                              type="checkbox"
                                              name="parentsApproval"
                                              value="no"
                                              onChange={(e) => onChange(e)}
                                              checked={parentsApproval == "yes"}
                                              invalid={
                                                errorList.parentsApproval
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className=" text-dark">
                                              Have parents approval? *
                                            </span>
                                          </Label>
                                          <Errors
                                            current_key="parentsApproval"
                                            key="parentsApproval"
                                          />
                                        </FormGroup>
                                      )}

                                      <Row>
                                        <Col xs={6}>
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="gender"
                                            >
                                              {" "}
                                              Gender: *
                                            </Label>
                                            <FormGroup check inline>
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="gender"
                                                  value="m"
                                                  required
                                                  onChange={(e) => onChange(e)}
                                                  checked={!!gender.match(/m/i)}
                                                />{" "}
                                                Male{" "}
                                              </Label>
                                            </FormGroup>

                                            <FormGroup check inline>
                                              {"  "}
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="gender"
                                                  value="f"
                                                  required
                                                  onChange={(e) => onChange(e)}
                                                  checked={!!gender.match(/f/i)}
                                                />{" "}
                                                Female{" "}
                                              </Label>
                                            </FormGroup>

                                            <FormGroup check inline>
                                              {"  "}
                                              <Label check>
                                                <Input
                                                  type="radio"
                                                  name="gender"
                                                  required
                                                  value="o"
                                                  onChange={(e) => onChange(e)}
                                                  checked={!!gender.match(/o/i)}
                                                />{" "}
                                                Other{" "}
                                              </Label>
                                            </FormGroup>
                                            <Errors
                                              current_key="gender"
                                              key="gender"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col xs="6">
                                          <FormGroup>
                                            <Label htmlFor="ssn">
                                              {" "}
                                              SSN # *
                                            </Label>
                                            <Input
                                              type="text"
                                              id="ssn"
                                              name="ssn"
                                              maxLength="100"
                                              value={ssn}
                                              required
                                              autoComplete={uniqueString()}
                                              onChange={(e) => onChange(e)}
                                              invalid={
                                                errorList.ssn ? true : false
                                              }
                                            />
                                            <Errors
                                              current_key="ssn"
                                              key="ssn"
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>

                                      {gender.match(/f/i) &&
                                      testingType.includes("covid-19") ? (
                                        <FormGroup check inline>
                                          <Label
                                            check
                                            style={{ marginBottom: "20px" }}
                                          >
                                            <Input
                                              type="checkbox"
                                              name="isPregnant"
                                              id="isPregnant"
                                              onChange={(e) => onChange(e)}
                                              checked={isPregnant}
                                              invalid={
                                                errorList.isPregnant
                                                  ? true
                                                  : false
                                              }
                                            />
                                            Is the patient pregnant?
                                          </Label>
                                          <br />
                                        </FormGroup>
                                      ) : null}

                                      <FormGroup>
                                        <Label
                                          className="form-label"
                                          htmlFor="memberPassword"
                                        >
                                          Password *
                                        </Label>
                                        <Input
                                          type="password"
                                          className="form-control"
                                          id="memberPassword"
                                          name="memberPassword"
                                          maxLength="50"
                                          required
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onChange(e)}
                                          invalid={
                                            errorList.memberPassword
                                              ? true
                                              : false
                                          }
                                        />
                                        <Errors
                                          current_key="memberPassword"
                                          key="memberPassword"
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Label
                                          className="form-label"
                                          htmlFor="email"
                                        >
                                          Email
                                        </Label>
                                        <Input
                                          type="email"
                                          className="form-control"
                                          id="email"
                                          name="email"
                                          maxLength="50"
                                          value={email}
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onChange(e)}
                                          invalid={
                                            errorList.email ? true : false
                                          }
                                        />
                                        <Errors
                                          current_key="email"
                                          key="email"
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Label
                                          className="form-label"
                                          htmlFor="phone"
                                        >
                                          Phone
                                        </Label>
                                        <PhoneInput
                                          className="hk_custom_sag"
                                          autoComplete={uniqueString()}
                                          international={true}
                                          defaultCountry="US"
                                          countryOptionsOrder={[
                                            "US",
                                            "|",
                                            "...",
                                          ]}
                                          value={iute164_phone}
                                          name="phone"
                                          addInternationalOption={false}
                                          onChange={onPhoneChange}
                                          onCountryChange={onCountryChange}
                                        />
                                        <Errors
                                          current_key="phone"
                                          key="phone"
                                        />
                                      </FormGroup>
                                    </CardBody>
                                  </Col>

                                  <Col lg="6" md="6" sm="12">
                                    <CardHeader>
                                      <h3 className="form-label card-title">
                                        Address Information
                                      </h3>
                                    </CardHeader>

                                    <CardBody>
                                      <FormGroup>
                                        <Label htmlFor="line_1">
                                          {" "}
                                          Address Line 1*
                                        </Label>
                                        <Input
                                          type="text"
                                          id="line_1"
                                          name="line_1"
                                          maxLength="100"
                                          required
                                          value={line_1}
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onChange(e)}
                                          invalid={
                                            errorList.line_1 ? true : false
                                          }
                                        />
                                        <Errors
                                          current_key="line_1"
                                          key="line_1"
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Label htmlFor="line_2">
                                          {" "}
                                          Address Line 2
                                        </Label>
                                        <Input
                                          type="text"
                                          id="line_2"
                                          name="line_2"
                                          maxLength="100"
                                          value={line_2}
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onChange(e)}
                                          invalid={
                                            errorList.line_2 ? true : false
                                          }
                                        />
                                        <Errors
                                          current_key="line_2"
                                          key="line_2"
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <GMaps
                                          label="City, State, Country"
                                          setFormData={setFormData}
                                          removeErrors={removeErrors}
                                          setErrors={setErrors}
                                          placeholder={
                                            a_city_placeholder
                                              ? a_city_placeholder
                                              : "Please Enter Your City Name"
                                          }
                                          types={"(cities)"}
                                          autofill="false"
                                          autoComplete={uniqueString()}
                                          isRequired={false}
                                          invalid={
                                            errorList.a_city ? true : false
                                          }
                                        />
                                        <Errors
                                          current_key="a_city"
                                          key="a_city"
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Label htmlFor="zip_code">
                                          {" "}
                                          Zip Code *
                                        </Label>
                                        <Input
                                          type="text"
                                          id="zip_code"
                                          name="zip_code"
                                          maxLength="15"
                                          value={zip_code}
                                          required
                                          autoComplete={uniqueString()}
                                          onChange={(e) => onChange(e)}
                                          invalid={
                                            errorList.zip_code ? true : false
                                          }
                                        />
                                        <Errors
                                          current_key="zip_code"
                                          key="zip_code"
                                        />
                                      </FormGroup>
                                    </CardBody>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    lg="12"
                                    md="12"
                                    sm="12"
                                    className="hidden"
                                  >
                                    <CardHeader>
                                      <h3 className="form-label card-title">
                                        Other Information
                                      </h3>
                                    </CardHeader>

                                    <CardBody>
                                      <Row>
                                        <Col md="6">
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="username"
                                            >
                                              Username (Patient Identifier)
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="username"
                                              name="username"
                                              value={username}
                                              autoComplete={uniqueString()}
                                              readOnly
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="secondaryUsername"
                                            >
                                              Secondary Username (case
                                              insensitive)
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="secondaryUsername"
                                              name="secondaryUsername"
                                              maxLength="10"
                                              value={secondaryUsername}
                                              autoComplete={uniqueString()}
                                              onChange={(e) => onChange(e)}
                                              onKeyUp={(e) => onKeyUp(e)}
                                              invalid={
                                                errorList.secondaryUsername
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <Errors
                                              current_key="secondaryUsername"
                                              key="secondaryUsername"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup>
                                            <Label htmlFor="race"> Race </Label>
                                            <Select
                                              cacheOptions
                                              id="race"
                                              name="race"
                                              options={[
                                                ...Constants.raceOptions,
                                              ]}
                                              value={race}
                                              onChange={(e) =>
                                                handleSelect("race", e)
                                              }
                                              invalid={
                                                errorList.race ? true : false
                                              }
                                            />
                                            <Errors
                                              current_key="race"
                                              key="race"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="ethnicity"
                                            >
                                              {" "}
                                              Ethnicity :
                                            </Label>
                                            {getEthnicityOptions}
                                            <Errors
                                              current_key="ethnicity"
                                              key="ethnicity"
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col lg="6" md="6" sm="12">
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="alternativePhone"
                                            >
                                              Alternative Phone
                                            </Label>
                                            <PhoneInput
                                              className="hk_custom_sag"
                                              autoComplete={uniqueString()}
                                              international={true}
                                              defaultCountry="US"
                                              countryOptionsOrder={[
                                                "US",
                                                "|",
                                                "...",
                                              ]}
                                              value={alternativePhone_full}
                                              name="alternativePhone"
                                              addInternationalOption={false}
                                              onChange={
                                                onAlternativePhoneChange
                                              }
                                              onCountryChange={
                                                onAlternativeCountryChange
                                              }
                                            />
                                            <Errors
                                              current_key="alternativePhone"
                                              key="alternativePhone"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col lg="6" md="6" sm="12">
                                          <FormGroup>
                                            <Label htmlFor="occupation">
                                              {" "}
                                              Occupation{" "}
                                            </Label>
                                            <Input
                                              type="text"
                                              className="form-control"
                                              id="occupation"
                                              name="occupation"
                                              maxLength="200"
                                              value={occupation}
                                              autoComplete={uniqueString()}
                                              onChange={(e) => onChange(e)}
                                              invalid={
                                                errorList.occupation
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <Errors
                                              current_key="occupation"
                                              key="occupation"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col lg="6" md="6" sm="12">
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="employedInHealthcare"
                                            >
                                              {" "}
                                              Employed in Healthcare
                                            </Label>
                                            {getEmployedInHealthcateOptions}
                                            <Errors
                                              current_key="employedInHealthcare"
                                              key="employedInHealthcare"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup>
                                            <Label htmlFor="jobNumber">
                                              {" "}
                                              Job #{" "}
                                              {testingType.includes(
                                                "employment"
                                              )
                                                ? "*"
                                                : ""}
                                            </Label>
                                            <Input
                                              type="text"
                                              id="jobNumber"
                                              name="jobNumber"
                                              maxLength="100"
                                              value={jobNumber}
                                              // required={
                                              //   testingType.includes(
                                              //     "employment"
                                              //   )
                                              //     ? true
                                              //     : false
                                              // }
                                              autoComplete={uniqueString()}
                                              onChange={(e) => onChange(e)}
                                              invalid={
                                                errorList.jobNumber
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <Errors
                                              current_key="jobNumber"
                                              key="jobNumber"
                                            />
                                          </FormGroup>
                                        </Col>

                                        <Col sm="12">
                                          <FormGroup>
                                            <Label
                                              className="form-label"
                                              htmlFor="paymentBy"
                                            >
                                              Payment Method
                                            </Label>
                                            {getPaymentByOptions}
                                            <Errors
                                              current_key="paymentBy"
                                              key="paymentBy"
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Col>

                                  {false ? (
                                    <Col lg="12" md="12" sm="12" xs="12">
                                      <VaccineInformation
                                        formData={formData}
                                        setFormData={setFormData}
                                      />
                                    </Col>
                                  ) : null}

                                  {false && testingType.includes("covid-19") ? (
                                    <Col lg="12">
                                      <InsuranceDetails
                                        formData={formData}
                                        setFormData={setFormData}
                                      />
                                    </Col>
                                  ) : null}

                                  <Col lg="12">
                                    <ConsentForm
                                      formData={formData}
                                      setFormData={setFormData}
                                    />
                                  </Col>

                                  <Col lg="12">
                                    <CardFooter style={{ border: "none" }}>
                                      <p>
                                        I understand that my and/or child’s
                                        vaccination status and other information
                                        may be disclosed as law permits.
                                      </p>
                                      <FormGroup>
                                        {loadingSubmit ? (
                                          <Spinner />
                                        ) : (
                                          <Button
                                            type="submit"
                                            color="primary"
                                            className="float-right mr-1"
                                          >
                                            Submit
                                          </Button>
                                        )}
                                        <br></br>
                                      </FormGroup>
                                    </CardFooter>
                                  </Col>
                                </Row>
                              </Card>
                            </Form>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

EmployerSignUp.propTypes = {
  loading: PropTypes.bool.isRequired,
  verifyPatientInformation: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  isLoginError: state.auth.login,
  currentEmployee: state.Employee.currentEmployee,
  prePath: state.auth.prePath,
  loading: state.EmployerProfile.loadingEmployer,
  localityParams: state.Employee.sortingParams,
});

export default connect(mapStateToProps, {
  verifyPatientInformation,
  loadPage,
  setErrors,
  loadAuthPage,
  removeErrors,
  removeAlert,
  loginRedirect,
  notFound,
  getEmployeeById,
})(EmployerSignUp);
