import {
  FRONTLAB_GET_HL7,
  FRONTLAB_GET_HL7_PROCEDURE_CODES,
} from "actions/types";

const initialState = {
  HL7Credentials: {},
  HL7ProcedureCodes: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTLAB_GET_HL7:
      return {
        ...state,
        HL7Credentials: payload,
      }
    case FRONTLAB_GET_HL7_PROCEDURE_CODES:
      return {
        ...state,
        HL7ProcedureCodes: payload,
      }
    default:
      return state;
  }
}
