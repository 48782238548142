
import axios from "axios";

export default async (username, patientId) => {
  try {
    if (username) {
      const config = {
        headers: { "Content-Type": "application/json" },
      }
      let params = { username:username };
      if (patientId) {
        params = {
          ...params,
          patientId: patientId,
        }
      }
      const res = await axios.post(`/api/employer/employee/validateUsername`, params, config);
      return { data: res.data.response, status: 0};
    }
    return { msg: "", status: 1};
  } catch (err) {
    return { msg: err.response.data.message, status: err.response.status };
  }
}
