import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

const TermsAndConditions = () => {
  return (
  <>
    <Helmet>
      <title>Terms and Conditions - {process.env.REACT_APP_APP_NAME}</title>
    </Helmet>
    <div className="center-text-inActivelink">
      <h1 className="x-large text-primary">
        <div className="center-text">Terms and Conditions</div>
      </h1>
      <p className="large" style={{textAlign: "center", marginTop: "40px"}}><h4>coming soon</h4></p>
    </div>
  </>
  );
};

export default TermsAndConditions;
