import { FRONTEMPLOYER_DASHBOARD_GET_ORDERS_COUNT } from "actions/types";

const initialState = {
  todayOrdersCount: 0,
  thisWeekOrdersCount: 0,
  thisMonthOrdersCount: 0,
  thisYearOrdersCount: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_DASHBOARD_GET_ORDERS_COUNT:
      return {
        ...initialState,
        todayOrdersCount: payload.todayOrdersCount,
        thisWeekOrdersCount: payload.thisWeekOrdersCount,
        thisMonthOrdersCount: payload.thisMonthOrdersCount,
        thisYearOrdersCount: payload.thisYearOrdersCount,
      };

    default:
      return state;
  }
}
