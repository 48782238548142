import React from "react";
import { Link } from "react-router-dom";

import Alert from "views/Notifications/Alert";
import Errors from "views/Notifications/Errors";
import { Form, Input, FormGroup, Label } from "reactstrap";

const LoginForm = ({
  forgotPassword,
  history,
  errorList,
  formData,
  setFormData,
  onSubmit,
}) => {
  const { email, password, remember_me } = formData;

  const [type, setPassType] = React.useState("password");

  const onChange = (e) => {
    if (e.target.name === "remember_me") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const showHide = () => {
    setPassType(type === "input" ? "password" : "input");
  };

  return (
    <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
      <h3>Partner Login</h3>
      <Alert />
      <FormGroup>
        <Label className="form-label">Email *</Label>
        <Input
          type="email"
          name="email"
          maxLength="50"
          value={email}
          onChange={(e) => onChange(e)}
          required
          autoComplete="off"
          invalid={errorList.email ? true : false}
          tabIndex="1"
        />

        <Errors current_key="email" key="email" />
      </FormGroup>
      <FormGroup className="password-box">
        <Link
          to="#"
          className="float-right mt-1 color-blue"
          onClick={(e) => forgotPassword(history, "employer")}
          tabIndex="5"
        >
          Forgot password?
        </Link>
        <Label className="form-label">Password*</Label>
        <Input
          type={type}
          name="password"
          minLength="8"
          value={password}
          autoComplete="off"
          onChange={(e) => onChange(e)}
          required
          tabIndex="2"
        />
        <i
          className={
            type === "input"
              ? "eye-symbol fa fa-eye eye-login"
              : "eye-symbol fa fa-eye-slash eye-login"
          }
          onClick={showHide}
        ></i>
      </FormGroup>

      <FormGroup>
        <Label className="custom-control custom-checkbox">
          <Input
            type="checkbox"
            name="remember_me"
            id="remember_me"
            className="custom-control-input"
            onChange={(e) => onChange(e)}
            checked={remember_me}
            tabIndex="3"
          />
          <span className="custom-control-label text-dark">Remember me</span>
        </Label>
      </FormGroup>
      <div className="form-footer mt-5">
        <button className="btn btn-primary btn-block" tabindex="4">
          {" "}
          Login{" "}
        </button>
      </div>
      {/* <div className="text-center top-auto">
        Don't have account yet?
        <b>
          {" "}
          <Link to="/register"> SignUp</Link>{" "}
        </b>
      </div> */}
    </Form>
  );
};

export default LoginForm;
