import {
  UPDATE_BUSINESS_TYPES,
  UPDATE_BUSINESS_CATEGORIES,
  UPDATE_ORG_SHORT_INFO,
} from "actions/types";

const initialState = {
  typesOfBusinessGlobal: [],
  categoriesOfBusinessGlobal: [],
  orgShortInfo: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_BUSINESS_TYPES:
      return {
        ...state,
        typesOfBusinessGlobal: payload,
      };
    case UPDATE_BUSINESS_CATEGORIES:
      return {
        ...state,
        categoriesOfBusinessGlobal: payload,
      };
    case UPDATE_ORG_SHORT_INFO:
      return {
        ...state,
        orgShortInfo: payload,
      };
    default:
      return state;
  }
}
