import { STATES_LIST } from "actions/types";

const initialState = {
  statesList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STATES_LIST:
      return {
        ...state,
        statesList: payload,
      };
    default:
      return state;
  }
}
