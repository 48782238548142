import {
  FRONTPHYSICIAN_TESTORDER_LIST_LOADING,
  FRONTPHYSICIAN_TESTORDER_RESET,
  FRONTPHYSICIAN_TESTORDER_LOAD_PAGE,
  FRONTPHYSICIAN_TESTORDER_ERROR,
  FRONTPHYSICIAN_TESTORDER_LOADING_ON_SUBMIT,
  FRONTPHYSICIAN_TESTORDER_GET_BY_ID,
  FRONTPHYSICIAN_TESTORDER_UPDATED,
  FRONTPHYSICIAN_TESTORDER_LIST_UPDATED,
  FRONTPHYSICIAN_TESTORDER_SEARCH_PARAMATERS_UPDATE,
  FRONTPHYSICIAN_TESTORDER_COMMENT_SUBMIT,
  FRONTPHYSICIAN_TESTORDER_COMMENT_COMPLETE,
  FRONTPHYSICIAN_TESTORDER_COMMENT_UPDATE,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_LOADING,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_TEXT,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_ON_SUBMIT,
  FRONTPHYSICIAN_EMPLOYEE_CONSENT_SUBMITED,
  FRONTPHYSICIAN_TESTORDER_MRO_OBSERVATION_UPDATE,
  FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_UPDATE,
  FRONTPHYSICIAN_TESTORDER_RESULT_UPLOADED_SINGLE,
  FRONTPHYSICIAN_TESTORDER_EMPLOYERS_LIST,
  FRONTPHYSICIAN_TESTORDER_EMPLOYEES_LIST,
  FRONTPHYSICIAN_EMPLOYER_BUSINESS_TYPES_UPDATED,
  FRONTPHYSICIAN_TESTORDER_CLIENTS_LIST,
  FRONTPHYSICIAN_TESTORDER_LOCATIONS_LIST,
  FRONTPHYSICIAN_TESTORDER_STATES_LIST,
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  testOrderList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentTestOrder: null,
  loadingTestOrderList: true,
  loadingTestOrder: true,
  // loadingUploadTestOrder: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
  loadingComment: false,
  currentEmployee: null,
  consentTextLoading: false,
  consentFormText: {},
  consentFormLoading: false,
  employersList: [],
  locationsList: [],
  clientList: [],
  employeesList: [],
  employerBusinessTypes: [],
  statesList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTPHYSICIAN_TESTORDER_RESET:
      return {
        ...initialState,
        loadingTestOrder: state.loadingTestOrder,
        loadingTestOrderList: state.loadingTestOrderList,
        loadingListData: state.loadingListData,
      };
    case FRONTPHYSICIAN_TESTORDER_LOAD_PAGE:
      return {
        ...state,
        loadingTestOrder: false,
        loadingListData: false,
        // loadingUploadTestOrder: false
      };
    case FRONTPHYSICIAN_TESTORDER_UPDATED:
      return {
        ...state,
        currentTestOrder: null,
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTPHYSICIAN_TESTORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingTestOrder: false,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
        loadingComment: false,
      };
    case FRONTPHYSICIAN_TESTORDER_GET_BY_ID:
      return {
        ...state,
        currentTestOrder: payload,
        loadingTestOrder: false,
      };
    case FRONTPHYSICIAN_TESTORDER_LIST_UPDATED:
      return {
        ...state,
        testOrderList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTPHYSICIAN_TESTORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case FRONTPHYSICIAN_TESTORDER_LIST_LOADING:
      return {
        ...state,
        loadingListData: true,
      };
    case FRONTPHYSICIAN_TESTORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingTestOrder: true,
        // loadingUploadTestOrder: true
      };
    case FRONTPHYSICIAN_TESTORDER_COMMENT_SUBMIT:
      return {
        ...state,
        loadingComment: true,
      };
    case FRONTPHYSICIAN_TESTORDER_COMMENT_COMPLETE:
      return {
        ...state,
        loadingComment: false,
      };
    case FRONTPHYSICIAN_TESTORDER_COMMENT_UPDATE:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          orderPatient: {
            ...state.currentTestOrder.orderPatient,
            physicianComment: payload.comment,
          },
        },
      };
    case FRONTPHYSICIAN_EMPLOYEE_CONSENT_LOADING:
      return {
        ...state,
        consentTextLoading: true,
      };
    case FRONTPHYSICIAN_EMPLOYEE_CONSENT_ERROR:
      return {
        ...state,
        consentTextLoading: false,
      };
    case FRONTPHYSICIAN_EMPLOYEE_CONSENT_TEXT:
      return {
        ...state,
        consentFormText: payload,
        consentTextLoading: false,
      };
    case FRONTPHYSICIAN_EMPLOYEE_CONSENT_ON_SUBMIT:
      return {
        ...state,
        consentFormLoading: true,
      };
    case FRONTPHYSICIAN_EMPLOYEE_CONSENT_SUBMITED:
      return {
        ...state,
        consentFormLoading: false,
        currentTestOrder: {
          ...state.currentTestOrder,
          orderPatient: {
            ...state.currentTestOrder.orderPatient,
            consentForm: payload,
          },
        },
      };
    case FRONTPHYSICIAN_TESTORDER_MRO_OBSERVATION_UPDATE:
      return {
        ...state,
        consentFormLoading: false,
        currentTestOrder: {
          ...state.currentTestOrder,
          orderPatient: {
            ...state.currentTestOrder.orderPatient,
            MRO_Observation: payload,
          },
        },
      };

    case FRONTPHYSICIAN_TESTORDER_PROVIDER_OBSERVATION_UPDATE:
      return {
        ...state,
        consentFormLoading: false,
        currentTestOrder: {
          ...state.currentTestOrder,
          orderPatient: {
            ...state.currentTestOrder.orderPatient,
            providerObservation: payload,
          },
        },
      };
    case FRONTPHYSICIAN_TESTORDER_RESULT_UPLOADED_SINGLE:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          order_items: state.currentTestOrder.order_items.map((eachItem) =>
            eachItem._id === payload.order_item_id
              ? {
                  ...eachItem,
                  results:
                    eachItem.results && eachItem.results.length
                      ? [...eachItem.results, payload.result]
                      : [payload.result],
                }
              : eachItem
          ),
        },
      };
    case FRONTPHYSICIAN_TESTORDER_EMPLOYERS_LIST:
      return {
        ...state,
        employersList: payload,
      };
    case FRONTPHYSICIAN_TESTORDER_EMPLOYEES_LIST:
      return {
        ...state,
        employeesList: payload,
      };
    case FRONTPHYSICIAN_EMPLOYER_BUSINESS_TYPES_UPDATED:
      return {
        ...state,
        employerBusinessTypes: payload,
      };
    case FRONTPHYSICIAN_TESTORDER_CLIENTS_LIST:
      return {
        ...state,
        clientList: payload,
      };
    case FRONTPHYSICIAN_TESTORDER_LOCATIONS_LIST:
      return {
        ...state,
        locationsList: payload,
      };
    case FRONTPHYSICIAN_TESTORDER_STATES_LIST:
      return {
        ...state,
        statesList: payload,
      };
    default:
      return state;
  }
}
