import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "views/Spinner";
import { loadUser } from "actions/auth";

import * as Constants from "constants/index";

const EmployerAdminLogin = ({ history, loadUser, user }) => {
  // const dispatch = useDispatch();

  //############### For adding Css ###########################

  useMemo(async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    let organizationId = params.get("organizationId");
    await localStorage.setItem("token", token);
    if (organizationId) {
      await sessionStorage.setItem("organizationId", organizationId);
    }
    await loadUser(token);
  }, []);

  useEffect(() => {
    if (user) {
      // console.log({ user });
      switch (user.role) {
        case Constants.LAB_ROLE:
          history.push("/lab");
          break;
        case Constants.EMPLOYER_ROLE:
          history.push("/organization");
          break;
        case Constants.PHYSICIAN_ROLE:
          if (
            user.isMRO ||
            (user.parentPhysician && user.parentPhysician.isMRO)
          )
            history.push("/physician/mro-dashboard");
          else history.push("/physician/dashboard");
          break;
        case Constants.STAFF_ROLE:
        case Constants.NURSE_ROLE:
        case Constants.PHLEBOTOMIST_ROLE:
          history.push("/staff/dashboard");
          break;
      }
    }
  }, [user]);

  return <Spinner />;
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(EmployerAdminLogin);
