import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetLink,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
} from "actions/auth";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import Spinner from "views/Spinner";
import { Helmet } from "react-helmet";

import { Form, FormGroup, Label, Input } from "reactstrap";
import LoginLayout from "./LoginLayout";

const ResetLink = ({
  resetLink,
  loadPage,
  loading,
  loadingOnLoginSubmit,
  loginRedirect,
  errorList,
  history,
}) => {
  const [onlyOnce, setOnce] = useState(true);

  useMemo(() => {
    if (onlyOnce) {
      loadPage();
      setOnce(false);
    }
  }, [loadPage]);

  //############### Define form state ###############################
  const [formData, setFormData] = useState({ email: "" });
  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    loadingOnLoginSubmit();
    resetLink({ email }).then((res) => {
      loadPage();
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Forgot password - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <LoginLayout>
        <h3>Forgot Password</h3>
        <Alert />
        <Form onSubmit={(e) => onSubmit(e)}>
          <FormGroup>
            <Label className="form-label text-dark">Email*</Label>
            <Input
              type="text"
              placeholder="Email"
              autoComplete="email"
              name="email"
              maxLength="50"
              value={email}
              onChange={(e) => onChange(e)}
              required
              invalid={errorList.email ? true : false}
            />
            <Errors current_key="email" key="email" />
          </FormGroup>
          <div className="form-footer mt-2">
            <button className="btn btn-primary btn-block">
              Send Password Reset Link
            </button>
          </div>
          <div className="text-center top-auto">
            Have an account?{" "}
            <b>
              <Link to="#" onClick={(e) => loginRedirect(history, "employer")}>
                SignIn
              </Link>{" "}
            </b>
          </div>
        </Form>
      </LoginLayout>
    </>
  );
};

ResetLink.propTypes = {
  resetLink: PropTypes.func.isRequired,
  loadingOnLoginSubmit: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  resetLink,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
})(ResetLink);
