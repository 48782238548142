import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { setAlert } from "actions/alert";
import {
  resetPassword,
  setPasswordError,
  getResetTokenStatus,
  tokenInvalid,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
} from "actions/auth";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";

import Spinner from "views/Spinner";
import LoginLayout from "./LoginLayout";

const ResetPassword = ({
  setAlert,
  setPasswordError,
  resetPassword,
  getResetTokenStatus,
  tokenInvalid,
  loadPage,
  history,
  errorList,
  match,
  loginRedirect,
}) => {
  const [onlyOnce, setOnce] = useState(true);
  const [type, setPassType] = useState("password");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  const { password, confirm_password } = formData;

  useMemo(() => {
    getResetTokenStatus(match.params.token).then((data) => {
      !data && tokenInvalid(history, "employer");
    });
    if (onlyOnce) {
      loadPage();
      setOnce(false);
    }
  }, [
    getResetTokenStatus,
    match.params.token,
    history,
    tokenInvalid,
    loadPage,
  ]);

  const showHide = () => {
    setPassType(type === "input" ? "password" : "input");
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      setPasswordError("Passwords do not match", "confirm_password");
      setAlert(
        "Errors! Please correct the following errors and submit again.",
        "danger"
      );
    } else {
      setLoading(true);
      resetPassword(password, match.params.token, history).then((res) => {
        if (res && res.status === true) {
          setLoading(false);
        }
      });
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Reset password - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <LoginLayout>
        <h3>Reset Password</h3>
        <Alert />
        <Form onSubmit={(e) => onSubmit(e)}>
          <FormGroup className="password-box">
            <Label className="form-label text-dark">Password*</Label>
            <Input
              type={type}
              placeholder="Password"
              autoComplete="password"
              name="password"
              minLength="8"
              value={password || ""}
              onChange={(e) => onChange(e)}
              invalid={errorList.password ? true : false}
            />
            <i
              className={
                (type === "input"
                  ? "eye-symbol fa fa-eye"
                  : "eye-symbol fa fa-eye-slash") + " eye-register"
              }
              onClick={showHide}
            ></i>
            <Errors current_key="password" key="password" />
          </FormGroup>
          <FormGroup>
            <Label className="form-label text-dark">Confirm Password*</Label>
            <Input
              type={type}
              placeholder="Confirm password"
              autoComplete="confirm_password"
              name="confirm_password"
              minLength="8"
              value={confirm_password || ""}
              onChange={(e) => onChange(e)}
              invalid={errorList.confirm_password ? true : false}
            />
            <Errors current_key="confirm_password" key="confirm_password" />
          </FormGroup>
          <div className="form-footer mt-2">
            <button className="btn btn-primary btn-block">
              Reset Password{" "}
            </button>
          </div>
          <div className="text-center  mt-3 text-dark">
            Have an account?{" "}
            <b>
              <Link to="#!" onClick={(e) => loginRedirect(history, "employer")}>
                SignIn
              </Link>{" "}
            </b>
          </div>
        </Form>
      </LoginLayout>
    </>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  loadingOnLoginSubmit: PropTypes.func.isRequired,
  tokenInvalid: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  setAlert,
  setPasswordError,
  resetPassword,
  getResetTokenStatus,
  tokenInvalid,
  loadPage,
  loadingOnLoginSubmit,
  loginRedirect,
})(ResetPassword);
