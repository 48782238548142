import { FRONTPHYSICIAN_DASHBOARD_GET_ORDERS_COUNT } from "actions/types";

const initialState = {
  todayServicesCount: 0,
  thisWeekServicesCount: 0,
  thisMonthServicesCount: 0,
  thisYearServicesCount: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTPHYSICIAN_DASHBOARD_GET_ORDERS_COUNT:
      return {
        ...initialState,
        todayServicesCount: payload.todayServicesCount,
        thisWeekServicesCount: payload.thisWeekServicesCount,
        thisMonthServicesCount: payload.thisMonthServicesCount,
        thisYearServicesCount: payload.thisYearServicesCount,
      };

    default:
      return state;
  }
}
