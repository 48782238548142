import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Errors from "views/Notifications/Errors";

import * as Constants from "constants/index";
import { uniqueString } from "utils/nodeHelper";
import { getVaccinationCard } from "actions/front/Employer/Employee";

const VaccineInformation = ({
  isDisabled,
  errorList,
  formData,
  setFormData,
  currentEmployee,
  getVaccinationCard,
}) => {
  const {
    minDate,
    vaccineType,
    firstDoseDone,
    firstDoseDate,
    secondDoseDone,
    secondDoseDate,
    boosterDone,
    boosterDate,
  } = formData;

  const [onlyOnce, setOnce] = useState(2);
  useEffect(() => {
    if (onlyOnce > 0 && currentEmployee) {
      setOnce(onlyOnce - 1);
      const { vaccineInfo } = currentEmployee;

      let details = {
        vaccineType:
          vaccineInfo && vaccineInfo.vaccineType ? vaccineInfo.vaccineType : "",
        firstDoseDone:
          vaccineInfo && vaccineInfo.firstDoseDone
            ? vaccineInfo.firstDoseDone
            : "",
        firstDoseDate:
          vaccineInfo && vaccineInfo.firstDoseDate
            ? moment(vaccineInfo.firstDoseDate).format("YYYY-MM-DD")
            : "",
        secondDoseDone:
          vaccineInfo && vaccineInfo.secondDoseDone
            ? vaccineInfo.secondDoseDone
            : "",
        secondDoseDate:
          vaccineInfo && vaccineInfo.secondDoseDate
            ? moment(vaccineInfo.secondDoseDate).format("YYYY-MM-DD")
            : "",
        boosterDone:
          vaccineInfo && vaccineInfo.boosterDone ? vaccineInfo.boosterDone : "",
        boosterDate:
          vaccineInfo && vaccineInfo.boosterDate
            ? moment(vaccineInfo.boosterDate).format("YYYY-MM-DD")
            : "",
      };

      setFormData({
        ...formData,
        ...details,
      });
    }
  }, [formData, currentEmployee]);

  const onChange = (e) => {
    switch (e.target.name) {
      case "vaccinationCardFront":
      case "vaccinationCardBack":
        setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        break;
      case "firstDoseDone":
      case "secondDoseDone":
      case "boosterDone":
        if (e.target.checked) {
          setFormData({
            ...formData,
            [e.target.name]: "yes",
          });
        } else {
          setFormData({
            ...formData,
            [e.target.name]: "no",
          });
        }
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onDateChange = (e, name) => {
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };

  const getVaccineTypeOptions = (
    <>
      {Constants.vaccineTypeOptions.map((option, index) => {
        return (
          <FormGroup check inline key={index}>
            {"  "}
            <Label check>
              <Input
                type="radio"
                name="vaccineType"
                value={option.value}
                onChange={(e) => !isDisabled && onChange(e)}
                checked={vaccineType === option.value}
              />{" "}
              {option.label}{" "}
            </Label>
          </FormGroup>
        );
      })}
    </>
  );

  const downloadCard = (blobFileURL, fileName) => {
    // create and remove link to download pdf
    const link = document.createElement("a");
    link.href = blobFileURL;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const [vaccinationCardFrontPreview, setVaccinationCardFrontPreview] =
    useState("");
  useMemo(() => {
    if (currentEmployee && currentEmployee.vaccineInfo) {
      let vaccineInfo = currentEmployee.vaccineInfo;
      if (
        vaccineInfo.vaccinationCardFront &&
        vaccineInfo.vaccinationCardFront.path
      ) {
        getVaccinationCard(currentEmployee._id, "front").then((blob) => {
          if (!blob) {
            setVaccinationCardFrontPreview("-");
            return;
          }

          const fileURL = window.URL.createObjectURL(
            new Blob([blob], {
              type: vaccineInfo.vaccinationCardFront.fileType,
            })
          );
          const imageFrontPreview = (
            <div
              className="image-preview"
              title="Click to download"
              onClick={() =>
                downloadCard(fileURL, vaccineInfo.vaccinationCardFront.fileName)
              }
            >
              <img src={fileURL}></img>
            </div>
          );
          setVaccinationCardFrontPreview(imageFrontPreview);
        });
      }
    }
  }, [currentEmployee]);

  const [vaccinationCardBackPreview, setVaccinationCardBackPreview] =
    useState("");
  useMemo(() => {
    if (currentEmployee && currentEmployee.vaccineInfo) {
      let vaccineInfo = currentEmployee.vaccineInfo;
      if (
        vaccineInfo.vaccinationCardBack &&
        vaccineInfo.vaccinationCardBack.path
      ) {
        getVaccinationCard(currentEmployee._id, "back").then((blob) => {
          if (!blob) {
            setVaccinationCardBackPreview("-");
            return;
          }

          const fileURL = window.URL.createObjectURL(
            new Blob([blob], { type: vaccineInfo.vaccinationCardBack.fileType })
          );
          const imageFrontPreview = (
            <div
              className="image-preview"
              title="Click to download"
              onClick={() =>
                downloadCard(fileURL, vaccineInfo.vaccinationCardBack.fileName)
              }
            >
              <img src={fileURL}></img>
            </div>
          );
          setVaccinationCardBackPreview(imageFrontPreview);
        });
      }
    }
  }, [currentEmployee]);

  return (
    <Card>
      <CardHeader>
        <h3 className="card-title">Vaccination Information</h3>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="12" md="12" lg="12" xs="12">
            <FormGroup>
              <Label className="form-label" htmlFor="employedInHealthcare">
                {" "}
                Vaccine Type
              </Label>
              {getVaccineTypeOptions}
              <Errors current_key="vaccineType" key="vaccineType" />
            </FormGroup>
          </Col>
        </Row>

        {vaccineType !== "0" && (
          <Row>
            <Col sm="12" md="12" lg="12" xs="12">
              <FormGroup>
                <Row>
                  <Col sm="3" md="3" lg="3" xs="12">
                    <Label>
                      <Input
                        type="checkbox"
                        name="firstDoseDone"
                        value="yes"
                        onChange={(e) => !isDisabled && onChange(e)}
                        checked={firstDoseDone === "yes"}
                        invalid={errorList.firstDoseDone ? true : false}
                        style={{
                          position: "initial",
                          margin: "0 10px 0 0",
                        }}
                      />
                      <span
                        className="text-dark text-strong"
                        style={{ marginRight: "10px" }}
                      >
                        1<sup>st</sup> dose
                      </span>
                    </Label>
                  </Col>
                  {firstDoseDone === "yes" && (
                    <Col sm="9" md="9" lg="9" xs="12">
                      <span className="text-dark text-strong mr-15">
                        Select a date{" "}
                      </span>
                      <Input
                        type="Date"
                        id="example-datepicker"
                        className="inline-input"
                        value={firstDoseDate}
                        max={minDate}
                        autoComplete={uniqueString()}
                        onChange={(e) => onDateChange(e, "firstDoseDate")}
                        disabled={isDisabled}
                      />
                    </Col>
                  )}
                </Row>

                <Errors current_key="firstDose" key="firstDose" />
              </FormGroup>
            </Col>

            <Col sm="12" md="12" lg="12" xs="12">
              <FormGroup>
                <Row>
                  <Col sm="3" md="3" lg="3" xs="12">
                    <Label>
                      <Input
                        type="checkbox"
                        name="secondDoseDone"
                        value="yes"
                        onChange={(e) => !isDisabled && onChange(e)}
                        checked={secondDoseDone === "yes"}
                        invalid={errorList.secondDoseDone ? true : false}
                        style={{
                          position: "initial",
                          margin: "0 10px 0 0",
                        }}
                      />
                      <span
                        className="text-dark text-strong"
                        style={{ marginRight: "10px" }}
                      >
                        2<sup>nd</sup> dose
                      </span>
                    </Label>
                  </Col>
                  {secondDoseDone === "yes" && (
                    <Col sm="9" md="9" lg="9" xs="12">
                      <span className="text-dark text-strong mr-15">
                        Select a date{" "}
                      </span>
                      <Input
                        type="Date"
                        id="example-datepicker"
                        className="inline-input"
                        value={secondDoseDate}
                        max={minDate}
                        autoComplete={uniqueString()}
                        onChange={(e) => onDateChange(e, "secondDoseDate")}
                        disabled={isDisabled}
                      />
                    </Col>
                  )}
                </Row>

                <Errors current_key="secondDose" key="secondDose" />
              </FormGroup>
            </Col>

            <Col sm="12" md="12" lg="12" xs="12">
              <FormGroup>
                <Row>
                  <Col sm="3" md="3" lg="3" xs="12">
                    <Label>
                      <Input
                        type="checkbox"
                        name="boosterDone"
                        value="yes"
                        onChange={(e) => !isDisabled && onChange(e)}
                        checked={boosterDone === "yes"}
                        invalid={errorList.boosterDone ? true : false}
                        style={{
                          position: "initial",
                          margin: "0 10px 0 0",
                        }}
                      />
                      <span
                        className="text-dark text-strong"
                        style={{ marginRight: "10px" }}
                      >
                        Booster
                      </span>
                    </Label>
                  </Col>
                  {boosterDone === "yes" && (
                    <Col sm="9" md="9" lg="9" xs="12">
                      <span className="text-dark text-strong mr-15">
                        Select a date{" "}
                      </span>
                      <Input
                        type="Date"
                        id="example-datepicker"
                        className="inline-input"
                        value={boosterDate}
                        max={minDate}
                        autoComplete={uniqueString()}
                        onChange={(e) => onDateChange(e, "boosterDate")}
                        disabled={isDisabled}
                      />
                    </Col>
                  )}
                </Row>

                <Errors current_key="booster" key="booster" />
              </FormGroup>
            </Col>

            <Col sm="6" md="6" lg="6" xs="12">
              <FormGroup>
                <Label htmlFor="vaccinationCardFront">
                  Vaccination card front
                </Label>
                {!isDisabled ? (
                  <Input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="vaccinationCardFront"
                    onChange={(e) => onChange(e)}
                  />
                ) : null}
                <Errors
                  current_key="vaccinationCardFront"
                  key="vaccinationCardFront"
                />

                <div className="card-preview">
                  {vaccinationCardFrontPreview}
                </div>
              </FormGroup>
            </Col>

            <Col sm="6" md="6" lg="6" xs="12">
              <FormGroup>
                <Label htmlFor="vaccinationCardBack">
                  Vaccination card back
                </Label>
                {!isDisabled ? (
                  <Input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="vaccinationCardBack"
                    onChange={(e) => onChange(e)}
                  />
                ) : null}
                <Errors
                  current_key="vaccinationCardBack"
                  key="vaccinationCardBack"
                />

                <div className="card-preview">{vaccinationCardBackPreview}</div>
              </FormGroup>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

VaccineInformation.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  currentEmployee: state.Employee.currentEmployee,
});

export default connect(mapStateToProps, {
  getVaccinationCard,
})(VaccineInformation);
