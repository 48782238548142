import {
  FRONTEMPLOYER_LOADING_ORGANIZATION_LIST_DATA,
  FRONTEMPLOYER_ORGANIZATION_LIST,
} from "actions/types";

const initialState = {
  loadingListData: true,
  organizationList: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: payload,
        loadingListData: false,
      };
    case FRONTEMPLOYER_LOADING_ORGANIZATION_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
      default:
        return state;
    }
}
