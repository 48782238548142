import {
  FRONTEMPLOYER_RECURRING_ORDER_LIST_LOADING,
  FRONTEMPLOYER_RECURRING_ORDER_RESET,
  FRONTEMPLOYER_RECURRING_ORDER_LOAD_PAGE,
  FRONTEMPLOYER_RECURRING_ORDER_ERROR,
  FRONTEMPLOYER_RECURRING_ORDER_CHANGE_STATUS,
  FRONTEMPLOYER_RECURRING_ORDER_CREATED,
  FRONTEMPLOYER_RECURRING_ORDER_LOADING_ON_SUBMIT,
  FRONTEMPLOYER_RECURRING_ORDER_GET_BY_ID,
  FRONTEMPLOYER_RECURRING_ORDER_UPDATED,
  FRONTEMPLOYER_RECURRING_ORDER_LIST_UPDATED,
  FRONTEMPLOYER_RECURRING_ORDER_QUALIFICATION_DELETE,
  FRONTEMPLOYER_RECURRING_ORDER_DELETE,
  FRONTEMPLOYER_RECURRING_ORDER_UPLOADED,
  FRONTEMPLOYER_RECURRING_ORDER_EMPLOYEES_LIST,
  FRONTEMPLOYER_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE,
} from "actions/types";
import * as Constants from "constants/index";
//
const initialState = {
  testOrderList: {
    page: 1,
    data: [],
    count: 0
  },
  employeeList: null,
  currentTestOrder: null,
  loadingTestOrderList: true,
  loadingTestOrder: true,
  // loadingUploadTestOrder: false,
  loadingListData: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  performDelete: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_RECURRING_ORDER_RESET:
      return {
        ...initialState,
        loadingTestOrder: state.loadingTestOrder,
        loadingTestOrderList: state.loadingTestOrderList,
        loadingListData: state.loadingListData,
      }
    case FRONTEMPLOYER_RECURRING_ORDER_LOAD_PAGE:
      return {
        ...state,
        loadingTestOrder: false,
        loadingListData: false,
        // loadingUploadTestOrder: false
      };
    case FRONTEMPLOYER_RECURRING_ORDER_CREATED:
      return {
        ...state,
        loadingTestOrder: false
      };
    case FRONTEMPLOYER_RECURRING_ORDER_UPDATED:
      return {
        ...state,
        currentTestOrder: null,
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      };
    case FRONTEMPLOYER_RECURRING_ORDER_DELETE:
      const currentCount = state.testOrderList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.testOrderList.page);
      var remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        testOrderList: {
          data: state.testOrderList.data.filter(employee =>
            employee._id !== payload
          ),
          count: currentCount - 1,
          page: currentPage <= remainingPages ? currentPage.toString() : remainingPages.toString(),
        },
        performDelete: true,
        loadingListData: false
      };
    case FRONTEMPLOYER_RECURRING_ORDER_ERROR:
      return {
        ...state,
        error: payload,
        loadingTestOrder: false,
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_RECURRING_ORDER_EMPLOYEES_LIST:
      return {
        ...state,
        employeeList: payload
      };
    case FRONTEMPLOYER_RECURRING_ORDER_GET_BY_ID:
      return {
        ...state,
        currentTestOrder: payload,
        loadingTestOrder: false,
      };
    case FRONTEMPLOYER_RECURRING_ORDER_QUALIFICATION_DELETE:
      return {
        ...state,
        currentTestOrder: {
          ...state.currentTestOrder,
          qualifications: state.currentTestOrder.qualifications.filter(cert =>
            cert._id !== payload
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingTestOrder: false,
      }
    case FRONTEMPLOYER_RECURRING_ORDER_LIST_UPDATED:
      return {
        ...state,
        testOrderList: {
          data: payload.data,
          page: payload.metadata[0].current_page,
          count: payload.metadata[0].totalRecord,
        },
        loadingTestOrderList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTEMPLOYER_RECURRING_ORDER_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload }
      };
    case FRONTEMPLOYER_RECURRING_ORDER_CHANGE_STATUS:
      return {
        ...state,
        testOrderList: {
          ...state.testOrderList,
          data: state.testOrderList.data.map(employee =>
            employee._id === payload._id
              ? { ...employee, status: payload.status }
              : employee
          )
        },
        loadingListData: false,
      };
    case FRONTEMPLOYER_RECURRING_ORDER_LIST_LOADING:
      return {
        ...state,
        loadingListData: true
      };
    case FRONTEMPLOYER_RECURRING_ORDER_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingTestOrder: true,
        // loadingUploadTestOrder: true
      };
    default:
      return state;
  }
}
