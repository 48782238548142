import React, { useState, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "actions/alert";
import {
  verifyEmail,
  loginRedirect
} from "actions/verification";
import Spinner from "views/Spinner";
import { Helmet } from "react-helmet";

import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardText
} from "reactstrap";

const VerifyEmail = ({
  verifyEmail,
  loginRedirect,
  authentication,
  history,
  match,
  loading,
}) => {
  const [successful, setSuccessful] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);

  useMemo(() => {
    if(authentication) return loginRedirect();
    verifyEmail(match.params.token).then(data => {
      if(!data || !data.status) return setInvalidToken(true);
      setSuccessful(true);
    });
  }, [verifyEmail, match.params.token, history, authentication]);

  return (
    <div className="app flex-row align-items-center">
      <Helmet>
        <title>Verify email - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container>
      {
          loading?<Spinner/>:(
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <Card className="mx-4" color={successful?"success":invalidToken?"danger":"white"}>
                <CardBody className="p-4">
                  {
                    successful?(
                      <CardText>
                        <span>Email has been verified successfully. Click here to </span>
                        <Link to="/login" title="login" className="text-dark text-underline">
                          Login
                        </Link>
                      </CardText>
                    ):(
                      invalidToken ?(
                        <CardText>
                          <span>Activation link has been expired/ invalidated. Go back to </span>
                          <Link to="/" title="home" className="text-dark text-underline">
                            Home
                          </Link>
                        </CardText>
                      ):(<CardText></CardText>)
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        )
      }
      </Container>
    </div>
  );
};

VerifyEmail.propTypes = {
  setAlert: PropTypes.func.isRequired,
  verifyEmail: PropTypes.func.isRequired,
  loginRedirect: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  errorList: state.errors,
  loading: state.verification.loadingEmailVerification,
  authentication: state.auth.authentication
});

export default connect(mapStateToProps, {
  setAlert,
  verifyEmail,
  loginRedirect
})(withRouter(VerifyEmail));
