import { FRONTMRO_DASHBOARD_GET_DRUGTESTS_COUNT } from "actions/types";

const initialState = {
  todayDrugTestsCount: 0,
  thisWeekDrugTestsCount: 0,
  thisMonthDrugTestsCount: 0,
  thisYearDrugTestsCount: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTMRO_DASHBOARD_GET_DRUGTESTS_COUNT:
      return {
        ...initialState,
        todayDrugTestsCount: payload.todayDrugTestsCount,
        thisWeekDrugTestsCount: payload.thisWeekDrugTestsCount,
        thisMonthDrugTestsCount: payload.thisMonthDrugTestsCount,
        thisYearDrugTestsCount: payload.thisYearDrugTestsCount,
      };

    default:
      return state;
  }
}
