import {
  CLIENT_CREATED,
  CLIENT_ERROR,
  CLIENT_DELETE,
  CLIENT_UPDATED,
  CLIENT_LIST_UPDATED,
  GET_CLIENT_BY_ID,
  LOADING_ON_CLIENT_SUBMIT,
  LOADING_CLIENT_LIST_DATA,
  CHANGE_CLIENT_STATUS,
  RESET,
} from "actions/types";

const initialState = {
  clientList: {
    data: [],
  },
  currentClient: {},
  loadingClientList: true,
  loadingClient: true,
  loadingListData: true,
  performDelete: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return initialState;
    case CLIENT_CREATED:
      return {
        ...state,
        loadingClient: false,
      };
    case CLIENT_UPDATED:
      return {
        ...state,
        clientList: {
          ...state.clientList,
          data: state.clientList.data.map((client) =>
            client._id === payload._id ? { ...client, ...payload } : client
          ),
        },
        loadingClientList: false,
        loadingClient: false,
      };
    case CLIENT_DELETE:
      return {
        ...state,
        clientList: {
          data: state.clientList.data.filter(
            (client) => client._id !== payload
          ),
        },
        loadingClientList: false,
        performDelete: true,
      };
    case CLIENT_ERROR:
      return {
        ...state,
        error: payload,
        loadingClient: false,
        loadingClientList: false,
        loadingListData: false,
        performDelete: false,
      };
    case GET_CLIENT_BY_ID:
      return {
        ...state,
        currentClient: payload,
        loadingClient: false,
      };
    case CLIENT_LIST_UPDATED:
      return {
        ...state,
        clientList: {
          ...payload,
        },
        loadingClientList: false,
        loadingClient: false,
        loadingListData: false,
        performDelete: false,
      };
    case LOADING_CLIENT_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_CLIENT_SUBMIT:
      return {
        ...state,
        loadingClient: true,
      };
    case CHANGE_CLIENT_STATUS:
      return {
        ...state,
        clientList: {
          ...state.clientList,
          data: state.clientList.data.map((client) =>
            client._id === payload._id
              ? { ...client, status: payload.status }
              : client
          ),
        },
      };
    default:
      return state;
  }
}
