import axios from "axios";
import { setAlert } from "actions/alert";

import {
  REMOVE_ERRORS,
  LOCALITY_ERROR,
  LOCALITY_LIST_UPDATED,
  LOCALITY_SEARCH_PARAMATERS_UPDATE,
  LOADING_LOCALITY_LIST_DATA,
  LOADING_CITY_LIST_DATA,
  CITY_SEARCH_PARAMATERS_UPDATE,
  CITY_LIST_UPDATED,
  CITY_ERROR,
  USER_UPDATE_USER_PROFILE,
  USER_LOAD_USER_PROFILE,
  USER_PROFILE_UPLOAD_ERROR,
  USER_PROFILE_UPLOADING,
  UPDATE_BUSINESS_TYPES,
  UPDATE_BUSINESS_CATEGORIES,
} from "actions/types";

import { logout } from "actions/auth";

export const getLocalityList =
  (city_id, localityParams, notRemoveError) => async (dispatch) => {
    if (!notRemoveError) {
      dispatch({ type: REMOVE_ERRORS });
      dispatch({ type: LOADING_LOCALITY_LIST_DATA });
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const query = localityParams.query ? localityParams.query : "";
      localityParams.query = query;
      config.params = localityParams;
      const res = await axios.get(`/api/city/${city_id}/locality`, config);
      // dispatch({ type: REMOVE_ALERT });
      dispatch({
        type: LOCALITY_SEARCH_PARAMATERS_UPDATE,
        payload: localityParams,
      });
      dispatch({
        type: LOCALITY_LIST_UPDATED,
        payload: res.data.response[0],
      });
      return res.data ? res.data : { status: false };
    } catch (err) {
      //console.log(err);
      if (err.response) {
        dispatch({
          type: LOCALITY_ERROR,
          payload: {
            msg: err.response.statusText,
            status: err.response.status,
          },
        });
      }
    }
  };

//get City list
export const getCityList = (cityParams, notRemoveError) => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    if (!notRemoveError) {
      dispatch({ type: REMOVE_ERRORS });
      dispatch({ type: LOADING_CITY_LIST_DATA });
    }
    const query = cityParams.query ? cityParams.query : "";
    cityParams.query = query;
    config.params = cityParams;
    const res = await axios.get(`/api/city`, config);
    // dispatch({ type: REMOVE_ALERT });
    dispatch({
      type: CITY_SEARCH_PARAMATERS_UPDATE,
      payload: cityParams,
    });
    dispatch({
      type: CITY_LIST_UPDATED,
      payload: res.data.response[0],
    });
    return res.data ? res.data : { status: false };
  } catch (err) {
    dispatch({
      type: CITY_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const setProfileUploadError = (error) => async (dispatch) => {
  dispatch({
    type: USER_PROFILE_UPLOAD_ERROR,
    payload: error,
  });
};

export const saveUserProfile = (userProfileImage) => async (dispatch) => {
  try {
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const submitData = {
      userProfileImage,
    };

    dispatch({
      type: USER_PROFILE_UPLOADING,
    });

    const res = await axios.put(
      `/api/user/upload-user-profile`,
      submitData,
      config
    );

    if (res.data.status !== true) {
      const errors = res.data.errors;
      const firstError = errors.length
        ? errors[0].msg
        : res.data.message
        ? res.data.message
        : "Something Went Wrong";

      dispatch({
        type: USER_PROFILE_UPLOAD_ERROR,
        payload: firstError,
      });
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(
          setAlert(
            (err.response.data && err.response.data.message
              ? err.response.data.message
              : err.response.statusText) || "Something went wrong",
            "danger"
          )
        );
      }
    }
  }
};

export const getUserProfile = () => async (dispatch) => {
  try {
    const config = { responseType: "blob" };

    dispatch({ type: USER_LOAD_USER_PROFILE });

    const res = await axios.get(
      `/api/user/get-user-profile?timestamp=${Date.now()}`,
      config
    );
    console.log(res.data);
    dispatch({ type: USER_UPDATE_USER_PROFILE, payload: res.data });

    return res.data;
  } catch (err) {
    console.log(err);
    dispatch({ type: USER_UPDATE_USER_PROFILE, payload: null });
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      }
    }
  }
};

export const getBusinessTypes = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/business-types`, config);

    if (res.data.status === true) {
      dispatch({
        type: UPDATE_BUSINESS_TYPES,
        payload: res.data.response,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getBusinessCategories = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/business-categories`, config);

    if (res.data.status === true) {
      dispatch({
        type: UPDATE_BUSINESS_CATEGORIES,
        payload: res.data.response,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getGroupsByCategory = (category_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.get(`/api/common/${category_id}/groups`, config);

    return res.data && res.data.response ? res.data.response : [];
  } catch (err) {
    console.log(err);
  }
};

export const getInsuranceNameList = () => async () => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const res = await axios.get(`/api/common/insuranceNameList`, config);
    return res.data.response;
  } catch (err) {
    console.log(err);
  }
};
