import {
    FRONTSTAFF_ERROR,
    FRONTSTAFF_UPDATED,
    FRONTSTAFF_GET_BY_ID,
    FRONTSTAFF_LOADING_ON_SUBMIT,
    FRONTSTAFF_RESET,
    FRONTSTAFF_LOAD_PAGE,
} from "actions/types";

const initialState = {
    currentStaff: null,
    // authId: false,
    loadingStaff: true,
    loadingListData: true,
    performDelete: false,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FRONTSTAFF_RESET:
            return {
                ...initialState,
                loadingStaff: state.loadingStaff,
            }
        case FRONTSTAFF_LOAD_PAGE:
            return {
                ...state,
                loadingStaff: false
            };
        case FRONTSTAFF_UPDATED:
            return {
                ...state,
                currentStaff: payload,
                sortingParams: initialState.sortingParams,
                loadingStaff: false,
            };
        case FRONTSTAFF_ERROR:
            return {
                ...state,
                error: payload,
                loadingStaff: false,
                loadingStaffList: false,
                loadingListData: false,
                performDelete: false,
            };
        case FRONTSTAFF_GET_BY_ID:
            return {
                ...state,
                currentStaff: payload,
                loadingStaff: false,
            };
        case FRONTSTAFF_LOADING_ON_SUBMIT:
            return {
                ...state,
                loadingStaff: true
            };
        default:
            return state;
    }
}
