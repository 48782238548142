export const DEFAULT_PAGE_SIZE = 20;
export const TABLE_BORDER_COLOR = "#f1f5f1";
export const NO_RECORD = "No Record Found.";
export const ADMIN_ROLE = 1;
export const EMPLOYER_ROLE = 2;
export const LAB_ROLE = 3;
export const EMPLOYEE_ROLE = 4;
export const STAFF_ROLE = 5;
export const PHYSICIAN_ROLE = 6;
export const PHLEBOTOMIST_ROLE = 7;
export const NURSE_ROLE = 8;
export const INTERVAL_MASS_DOWNLOAD = 120; //seconds
export const INTERVAL_MASS_DOWNLOAD_PER_PATIENT = 4; //per patient in seconds

export const physicianRoleOptions = [
  {
    title: "Physician Assistant",
    value: "Physician_Assistant",
  },
  {
    title: "Physician",
    value: "Physician",
  },
  {
    title: "Specialist",
    value: "Specialist",
  },
];

export const staffRoleOptions = [
  {
    title: "Staff",
    value: 5,
  },
  {
    title: "Physician",
    value: 6,
  },
  {
    title: "Phlebotomist",
    value: 7,
  },
  {
    title: "Nurse/Medtech",
    value: 8,
  },
];

export const TEST_ORDER_STATUS = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "Scheduled",
  },
  {
    value: 3,
    label: "Sample Collected",
  },
  {
    value: 4,
    label: "Results Delivered",
  },
  {
    value: 5,
    label: "Cancelled",
  },
  {
    value: 6,
    label: "Partially Delivered",
  },
  {
    value: 7,
    label: "Pending",
  },
];

export const ethnicityOptions = [
  {
    value: "1",
    label: "HISPANIC",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "NON - HISPANIC",
    regex: new RegExp("2", "i"),
  },
];

export const paymentByOptions = [
  {
    value: "1",
    label: "Organization",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Insurance",
    regex: new RegExp("2", "i"),
  },
  {
    value: "3",
    label: "Self",
    regex: new RegExp("3", "i"),
  },
];

export const physicianFromOptions = [
  {
    value: "1",
    label: "iLabConnect",
    regex: new RegExp("1", "i"),
  },
  {
    value: "2",
    label: "Organization",
    regex: new RegExp("2", "i"),
  },
];

export const raceOptions = [
  {
    value: "1",
    label: "American Indian, Alaska Native",
  },
  {
    value: "2",
    label: "Asian",
  },
  {
    value: "3",
    label: "Black or African American",
  },
  {
    value: "4",
    label: "White",
  },
  {
    value: "5",
    label: "Other",
  },
];

export const patientDeactivationOptions = [
  {
    value: "2",
    label: "Deceased",
  },
  {
    value: "3",
    label: "Left facility",
  },
  {
    value: "1",
    label: "Other",
  },
];

export const collectionTypes = {
  covid19: ["Saliva", "Nasopharyngeal"],
  respiratoryPanel: ["Nasal", "Nasopharyngeal"],
  employment: ["Hair", "Oral", "Urine"],
};

export const testOrderBulkOperationOptions = [
  {
    value: "1",
    label: "Mass download",
    regex: new RegExp("1", "i"),
  },
];

export const vaccineTypeOptions = [
  {
    value: "0",
    label: "Unvaccinated",
  },
  {
    value: "1",
    label: "Pfizer",
  },
  {
    value: "2",
    label: "Moderna",
  },
  {
    value: "3",
    label: "Johnson and Johnson",
  },
];

export const regionsList = [
  { label: "Central", value: "Central" },
  { label: "Chesapeake", value: "Chesapeake" },
  { label: "Corporate", value: "Corporate" },
  { label: "MidAtlantic", value: "MidAtlantic" },
  { label: "Midwest", value: "Midwest" },
  { label: "North America", value: "North America" },
  { label: "Northeast", value: "Northeast" },
  { label: "Northwest", value: "Northwest" },
  { label: "Southeast", value: "Southeast" },
  { label: "Southwest", value: "Southwest" },
  { label: "TKO", value: "TKO" },
  { label: "West", value: "West" },
];

export const jobCodes = [
  { value: 110, label: "Services - Sciences" },
  { value: 114, label: "Enterprise Corporate Services" },
  { value: 115, label: "Operations & Administrative" },
  { value: 118, label: "Distrib & Logistics" },
  { value: 119, label: "Const, Env, & Energy" },
  { value: 120, label: "Facilities & Maint" },
  { value: 16, label: "Customer Support" },
  { value: 2, label: "Contract Engineering" },
  { value: 50, label: "Services - Engineering" },
  { value: 54, label: "Accounting and Finance" },
  { value: 6, label: "Manu, Robo, & Autom" },
  { value: 66, label: "Aviation" },
  { value: 8, label: "Contract Sciences" },
];

export const reasonForTestList = [
  {
    value: 3,
    label: "Post-Accident Testing",
  },
  {
    value: 1,
    label: "Pre-Employment Testing",
  },
  {
    value: 2,
    label: "Random Testing",
  },

  {
    value: 4,
    label: "Reasonable Suspicion Testing",
  },
  {
    value: 5,
    label: "Return To Duty Testing",
  },
];

export const idTypeOptions = [
  { label: "Driver's License", value: "License" },
  { label: "Passport", value: "Passport" },
  { label: "Other Government ID", value: "Other" },
];

export const employmentTest = [
  {
    value: "covid-19",
    label: "Covid-19 PCR",
  },
  {
    value: "respiratory-panel",
    label: "Respiratory Panel",
  },
  {
    value: "employment",
    label: "Employment Testing",
  },
];

export const testOrderCancelReasonOptions = [
  {
    value: 1,
    label: "Submitting an adulterated, diluted, or substituted specimen.",
  },
  {
    value: 2,
    label: "Refusing to submit a specimen",
  },
  {
    value: 3,
    label:
      "Refusing to cooperate with the collection process or submit to a follow-up testing or evaluation.",
  },
];

export const resultOptions = [
  {
    value: "positive",
    label: "Positive",
  },
  {
    value: "negative",
    label: "Negative",
  },
  {
    value: "inconclusive",
    label: "Inconclusive",
  },
  {
    value: "tnp",
    label: "Test not performed",
  },
];

export const reasonToCancelList = [
  {
    value: "No_Show",
    label: "No Show",
  },
  {
    value: "Duplicate_Order",
    label: "Duplicate Order",
  },
  {
    value: "Applicant_Withdrew_from_Job",
    label: "Applicant Withdrew from Job",
  },
  {
    value: "Accidental_Order_Entry",
    label: "Accidental Order Entry",
  },
  {
    value: "Scheduling_Conflict",
    label: "Scheduling Conflict",
  },
  {
    value: "System_Error",
    label: "System Error",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const orderDurations = [
  {
    value: "one-month",
    label: "One Month",
  },
  {
    value: "two-month",
    label: "Two Month",
  },
  {
    value: "three-month",
    label: "Three Month",
  },
];
