import {
  FRONTEMPLOYER_MY_LOCATIONS_LIST,
  FRONTEMPLOYER_CLIENT_LIST,
} from "actions/types";

const initialState = {
  clientList: [],
  locationsList: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTEMPLOYER_MY_LOCATIONS_LIST:
      return {
        ...state,
        locationsList: payload,
      };
      break;
    case FRONTEMPLOYER_CLIENT_LIST:
      return {
        ...state,
        clientList: payload,
      };
      break;
    default:
      return state;
  }
}
