import {
  FRONTLAB_ERROR,
  FRONTLAB_UPDATED,
  FRONTLAB_GET_BY_ID,
  FRONTLAB_CERTIFICATE_DELETE,
  FRONTLAB_LOADING_ON_SUBMIT,
  REMOVE_ERRORS,
  FRONTLAB_RESET,
  FRONTLAB_LOAD_PAGE,
  LOGIN_SUCCESS,
} from "actions/types";

const initialState = {
    currentLab: null,
    // authId: false,
    loadingLab: true,
    loadingListData: true,
    performDelete: false,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case FRONTLAB_RESET:
            return {
                ...initialState,
              loadingLab: state.loadingLab,
            }
        case FRONTLAB_LOAD_PAGE:
            return {
                ...state,
              loadingLab: false
            };
        case FRONTLAB_UPDATED:
            return {
              ...state,
              currentLab: payload,
              sortingParams: initialState.sortingParams,
              loadingLab: false,
            };
        case FRONTLAB_ERROR:
            return {
                ...state,
                error: payload,
                loadingLab: false,
                loadingLabList: false,
                loadingListData: false,
                performDelete: false,
            };
        case FRONTLAB_GET_BY_ID:
            return {
              ...state,
              currentLab: payload,
              loadingLab: false,
            };
        case FRONTLAB_CERTIFICATE_DELETE:
            return {
                ...state,
                currentLab: {
                  ...state.currentLab,
                },
                sortingParams: initialState.sortingParams,
                loadingLab: false,
            }
        case FRONTLAB_LOADING_ON_SUBMIT:
            return {
                ...state,
                loadingLab: true
            };
        default:
            return state;
    }
}
