import { combineReducers } from "redux";

import alert from "./alert";
import auth from "./auth";
import errors from "./errors";
import EmployerDashboard from "./front/Employer/EmployerDashboard";
import EmployerProfile from "./front/Employer/EmployerProfile";
import EmployerAnalytics from "./front/Employer/EmployerAnalyticsReducer";
import Locations from "./front/Employer/Locations";
import Clients from "./front/Employer/Clients";
import TestOrder from "./front/Employer/TestOrder";
import TestOrderLab from "./front/Lab/TestOrder";
import Employee from "./front/Employer/Employee";
import labProfile from "./front/Lab/LabProfile";
import labSetting from "./front/Lab/LabSetting";
import RecurringOrder from "./front/Employer/RecurringOrder";
import PhysicianProfile from "./front/Physician/PhysicianProfile";
import TestOrderPhysician from "./front/Physician/TestOrder";
import Physician from "./front/Employer/Physician";
import OrganizationList from "./front/Employer/organizationList";

import StaffProfile from "./front/Staff/StaffProfile";
import TestOrderStaff from "./front/Staff/TestOrder";
import staffOrderPatient from "./front/Staff/Patient";
import EmployerReducer from "./front/Employer/EmployerReducer";
import StateReducer from "./front/Employer/StateReducer";
import physicianOrderPatient from "./front/Physician/TestOrder";
import testConfig from "./testConfigReducer";
import PhysicianDashboard from "./front/Physician/PhysicianDashboard";
import UserProfile from "./front/Common/UserProfile";
import MRODashboard from "./front/Physician/MRODashboard";
import common from "./front/Common/commonReducer";

const appReducer = combineReducers({
  alert,
  auth,
  Locations,
  Clients,
  errors,
  Physician,
  //Employer
  labProfile,
  labSetting,
  TestOrder,
  TestOrderLab,
  EmployerProfile,
  Employee,
  RecurringOrder,
  PhysicianProfile,
  TestOrderPhysician,
  OrganizationList,
  StaffProfile,
  TestOrderStaff,
  staffOrderPatient,
  EmployerReducer,
  StateReducer,
  physicianOrderPatient,
  testConfig,
  EmployerDashboard,
  EmployerAnalytics,
  PhysicianDashboard,
  UserProfile,
  MRODashboard,
  common,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
