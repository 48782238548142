import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  login,
  forgotPassword,
  loadAuthPage,
  registerRedirect,
  loadUser,
  loadPage,
} from "actions/auth";
import { Helmet } from "react-helmet";

import Spinner from "views/Spinner";
import LoginForm from "./Login/LoginForm";
import TwoFactorForm from "./Login/TwoFactorForm";
import LoginLayout from "./LoginLayout";

import * as Constants from "constants/index";
import { ENABLE_RESEND_OTP_OPTION } from "actions/types";

const EmployerLogin = ({
  login,
  loadUser,
  loadPage,
  loading,
  isAuthenticated,
  otpSentForLogin,
  enableResendOTPOption,
  forgotPassword,
  loadAuthPage,
  history,
  errorList,
}) => {
  const dispatch = useDispatch();
  const [onlyOnce, setOnce] = useState(true);

  useMemo(() => {
    if (onlyOnce) {
      loadAuthPage(history);
      setOnce(false);
    }
    loadPage();
  }, [loadPage]);

  //#####################Set Form Data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember_me: "",
    otp: "",
    userType: Constants.EMPLOYER_ROLE,
  });

  const [loginOrTwoFactor, setloginOrTwoFactor] = useState("login");

  //######################## Submit form ##################################
  const onSubmit = (e) => {
    e.preventDefault();
    const submitData = {
      email: formData.email,
      password: formData.password,
      askForTwoFactor: true,
      userType: Constants.EMPLOYER_ROLE,
    };
    loadAuthPage(history);
    login(submitData, history).then((res) => {
      console.log("two factor asked.", "\n", "Resend OTP option enabled.");
      dispatch({
        type: ENABLE_RESEND_OTP_OPTION,
      });
      showResendOTPOptionWithTimer();
    });
  };

  useEffect(() => {
    if (otpSentForLogin === false) {
      setloginOrTwoFactor("login");
    }

    if (otpSentForLogin === true) {
      setloginOrTwoFactor("twofactor");
    }
  }, [isAuthenticated, loadUser, otpSentForLogin]);

  const [showResendButton, setShowResendButton] = useState(false);
  const [textToShowForResendTimer, setTextToShowForResendTimer] = useState("");
  const showResendOTPOptionWithTimer = () => {
    var timeLeft = 60;
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        setShowResendButton(true);
        setTextToShowForResendTimer("");
        clearInterval(downloadTimer);
      } else {
        setTextToShowForResendTimer("Resend OTP in " + timeLeft + " seconds");
      }
      timeLeft -= 1;
    }, 1000);
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Login - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <LoginLayout>
        {loginOrTwoFactor === "login" && (
          <LoginForm
            formData={formData}
            setFormData={setFormData}
            errorList={errorList}
            history={history}
            onSubmit={onSubmit}
            forgotPassword={forgotPassword}
            showResendOTPOptionWithTimer={showResendOTPOptionWithTimer}
            showResendButton={showResendButton}
            setShowResendButton={setShowResendButton}
            textToShowForResendTimer={textToShowForResendTimer}
          />
        )}

        {loginOrTwoFactor === "twofactor" && (
          <TwoFactorForm
            formData={formData}
            setFormData={setFormData}
            errorList={errorList}
            login={login}
            enableResendOTPOption={enableResendOTPOption}
            loadAuthPage={loadAuthPage}
            history={history}
          />
        )}
      </LoginLayout>
    </>
  );
};

EmployerLogin.proTypes = {
  login: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  registerRedirect: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  otpSentForLogin: PropTypes.bool.isRequired,
  enableResendOTPOption: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  isAuthenticated: state.auth.isAuthenticated,
  authId: state.auth.authId,
  loading: state.auth.loading,
  otpSentForLogin: state.auth.otpSentForLogin,
  enableResendOTPOption: state.auth.enableResendOTPOption,
});

export default connect(mapStateToProps, {
  login,
  loadUser,
  loadPage,
  loadAuthPage,
  forgotPassword,
  registerRedirect,
})(EmployerLogin);
