import {
  LOCATION_CREATED,
  LOCATION_ERROR,
  LOCATION_DELETE,
  LOCATION_UPDATED,
  LOCATION_LIST_UPDATED,
  GET_LOCATION_BY_ID,
  LOCATION_SEARCH_PARAMATERS_UPDATE,
  CHANGE_LOCATION_STATUS,
  LOADING_ON_LOCATION_SUBMIT,
  LOADING_LOCATION_LIST_DATA,
  RESET,
  LOCATION_LIST_FOR_ORG,
} from "actions/types";
import * as Constants from "constants/index";

const initialState = {
  locationList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentLocation: {},
  loadingLocationList: true,
  loadingLocation: true,
  loadingListData: true,
  performDelete: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "created_at",
    ascending: "desc",
    query: "",
    filters: [],
  },
  locationListForOrg: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET:
      return initialState;
    case LOCATION_CREATED:
      let currentCount = state.locationList.count;
      let currentLimit = state.sortingParams.limit;
      let currentPage = parseInt(state.locationList.page);
      let currentMax = currentPage * currentLimit;
      return {
        ...state,
        // locationList:{
        //   ...state.locationList,
        //   data: (currentCount+1) <= currentMax ?[
        //     ...state.locationList.data, payload
        //   ]:[...state.locationList.data],
        //   count: state.locationList.count+1,
        // },
        // sortingParams: initialState.sortingParams,
        loadingLocation: false,
      };
    case LOCATION_UPDATED:
      return {
        ...state,
        locationList: {
          ...state.locationList,
          data: state.locationList.data.map((location) =>
            location._id === payload._id
              ? { ...location, ...payload }
              : location
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingLocationList: false,
        loadingLocation: false,
      };
    case LOCATION_DELETE:
      currentCount = state.locationList.count;
      currentLimit = state.sortingParams.limit;
      currentPage = parseInt(state.locationList.page);
      let remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      remainingPages = remainingPages ? remainingPages : 1;
      return {
        ...state,
        locationList: {
          data: state.locationList.data.filter(
            (location) => location._id !== payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        loadingLocationList: false,
        performDelete: true,
      };
    case LOCATION_ERROR:
      return {
        ...state,
        error: payload,
        loadingLocation: false,
        loadingLocationList: false,
        loadingListData: false,
        performDelete: false,
      };
    case GET_LOCATION_BY_ID:
      return {
        ...state,
        currentLocation: payload,
        loadingLocation: false,
      };
    case LOCATION_LIST_UPDATED:
      return {
        ...state,
        locationList: {
          ...payload,
        },
        loadingLocationList: false,
        loadingLocation: false,
        loadingListData: false,
        performDelete: false,
      };
    case LOCATION_LIST_FOR_ORG:
      return {
        ...state,
        locationListForOrg: payload,
      };
    case LOCATION_SEARCH_PARAMATERS_UPDATE:
      return {
        ...state,
        sortingParams: { ...payload },
      };
    case CHANGE_LOCATION_STATUS:
      return {
        ...state,
        locationList: {
          ...state.locationList,
          data: state.locationList.data.map((location) =>
            location._id === payload._id
              ? { ...location, status: payload.status }
              : location
          ),
        },
      };
    case LOADING_LOCATION_LIST_DATA:
      return {
        ...state,
        loadingListData: true,
      };
    case LOADING_ON_LOCATION_SUBMIT:
      return {
        ...state,
        loadingLocation: true,
      };

    default:
      return state;
  }
}
