import { employmentTest } from "constants/index";
import moment from "moment";
import store from "../store";
import { reasonToCancelList, TEST_ORDER_STATUS } from "constants/index";

// parse extension with filename
export const parseExtension = (filename) => {
  let extensionsList = filename.split(".");
  let extLen = extensionsList.length;
  return extensionsList[extLen - 1];
};

export const uniqueString = (length = 16) => {
  let randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const extractNameto3 = (fullname) => {
  try {
    fullname = fullname.trim();
    if (fullname) {
      let extractedInitials = fullname.split(" ");
      extractedInitials = extractedInitials.filter((n) => n);
      let returnName = {
        firstName: extractedInitials[0],
        lastName: extractedInitials[extractedInitials.length - 1],
        middleName: "",
      };
      extractedInitials.shift();
      extractedInitials.pop();
      if (extractedInitials.length) {
        returnName.middleName = extractedInitials.join(" ");
      }

      return returnName;
    }
    return {
      firstName: "",
      lastName: "",
      middleName: "",
    };
  } catch (err) {
    return {
      firstName: "",
      lastName: "",
      middleName: "",
    };
  }
};

export const displayOrgName = (orgInfo) => {
  if (!orgInfo.companyBuildingName) {
    return orgInfo.companyName;
  } else {
    return `${orgInfo.companyName}`; //  - ${orgInfo.companyBuildingName}
  }
};

export const concatCompanyLocationFullAddress = (address) => {
  return `${address.addressLabel} - ${
    address.addressLine +
    (address.addressLine2 ? " " + address.addressLine2 : "")
  }, ${address.city}, ${address.zipCode}`;
};

export const todayStandardDate = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return yyyy + "-" + mm + "-" + dd;
};

export const concatFullAddress = (address) => {
  // console.log({address});

  if (!address) return "";

  let fullAddress = `${address.line_1}`;

  if (address.line_2) {
    fullAddress = `${fullAddress} ${address.line_2}`;
  }

  if (address.city) {
    fullAddress = `${fullAddress}, ${address.city.city}, ${address.city.state}`;
  }

  if (address.zip_code) {
    fullAddress = `${fullAddress} ${address.zip_code}`;
  }

  if (address.city && address.city.short) {
    fullAddress = `${fullAddress} ${address.city.short}`;
  }

  return fullAddress;
};

export const getTestName = (testID) => {
  if (!testID) return "";

  const filtered = employmentTest.find((each) => each.value === testID);

  if (!filtered) return "";

  return filtered.label;
};

export const capitalize = ([first, ...rest]) => {
  return first.toUpperCase() + rest.join("").toLowerCase();
};

export const capitalizeFirst = ([first, ...rest]) => {
  return first.toUpperCase() + rest.join("");
};

export const getLast4WeekDates = () => {
  const today = moment();
  const week1firstday = today.clone().startOf("week").toDate();
  const week1lastday = today.clone().endOf("week").toDate();

  const week2firstday = today
    .clone()
    .subtract(1, "week")
    .startOf("week")
    .toDate();
  const week2lastday = today.clone().subtract(1, "week").endOf("week").toDate();

  const week3firstday = today
    .clone()
    .subtract(2, "week")
    .startOf("week")
    .toDate();
  const week3lastday = today.clone().subtract(2, "week").endOf("week").toDate();

  const week4firstday = today
    .clone()
    .subtract(3, "week")
    .startOf("week")
    .toDate();
  const week4lastday = today.clone().subtract(3, "week").endOf("week").toDate();

  return [
    [week1firstday, week1lastday],
    [week2firstday, week2lastday],
    [week3firstday, week3lastday],
    [week4firstday, week4lastday],
  ];
};

export const downloadFileToLocal = (fileURL, fileName) => {
  // create and remove link to download pdf
  const link = document.createElement("a");
  link.href = fileURL;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const isAllegisOrg = (employer) => {
  return employer && employer.orgParent === "Allegis" ? true : false;
};
export const getReasonToCancel = (reason) => {
  if (!reason) return "";

  const filtered = reasonToCancelList.find((each) => each.value === reason);

  if (!filtered) return "";

  return filtered.label;
};

export const getTestNameBySlug = (slug, category) => {
  const globalDrugTestsList = store.getState().testConfig.drugTestsGlobal;
  const labTestsGlobal = store.getState().testConfig.labTestsGlobal;
  const medicalTestsGlobal = store.getState().testConfig.medicalTestsGlobal;

  let globalTestList = [];
  switch (category) {
    case "drug":
      globalTestList = globalDrugTestsList;
      break;
    case "customize":
      return slug;
    case "lab":
      labTestsGlobal.forEach((each) => {
        globalTestList = [...globalTestList, ...each.testsIncluded];
      });
      break;
    case "medical":
      medicalTestsGlobal.forEach((each) => {
        globalTestList = [...globalTestList, ...each.testsIncluded];
      });
      break;
    default:
      globalTestList = [];
  }

  const filtered = globalTestList.find((each) => each.id === slug);
  if (!filtered) return slug;

  return filtered.name;
};

export const getProductsByCategory = (cat_ID) => {
  const productsListGlobal = store.getState().testConfig.productsListGlobal;
  return productsListGlobal.filter((g) => g.category === cat_ID);
};

export const getProductsWithGroups = () => {
  const productsListGlobal = store.getState().testConfig.productsListGlobal;

  let directProducts = [];
  productsListGlobal.forEach((group) => {
    const products = group.products.map((product) => ({
      group: {
        groupID: group.groupID,
        groupName: group.groupName,
        cat_ID: group.category,
      },
      ...product,
    }));
    directProducts = [...directProducts, ...products];
  });

  return directProducts;
};

export const getProductByID = (product_ID) => {
  const productsListGlobal = store.getState().testConfig.productsListGlobal;

  let globalTestList = [];
  productsListGlobal.forEach((group) => {
    const products = group.products.map((product) => ({
      group: {
        groupID: group.id,
        groupName: group.name,
      },
      ...product,
    }));
    globalTestList = [...globalTestList, ...products];
  });

  const filtered = globalTestList.find((each) => each.id === product_ID);
  if (!filtered) return {};

  return filtered;
};

export const getCategoryByID = (cat_ID) => {
  const CategoriesGlobal = store.getState().common.categoriesOfBusinessGlobal;

  if (!CategoriesGlobal.length) return {};

  const filtered = CategoriesGlobal.find((cat) => cat.cat_ID === cat_ID);

  if (!filtered) return {};

  return filtered;
};

export const getOrderStatusText = (status) => {
  const orderStatus = TEST_ORDER_STATUS.find((st) => st.value == status);

  if (!orderStatus) return "";

  return orderStatus.label;
};
