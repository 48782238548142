import React from "react";
import { sfold, checkScripts } from "utils/sFold";
import Loader from "./Front/Employer/Layout/Loader";

const InjectAssets = ({ children }) => {
  //############## Css and script ##############################
  const [loadingCSS, setLoadingCSS] = React.useState(true);
  const [loadingJS, setLoadingJS] = React.useState(true);
  const [onlyOnce, setOnce] = React.useState(true);
  let jsNeedToBeLoaded = 0;
  let jsLoaded = 0;
  let stylesNeedToBeLoaded = 0;
  let stylesLoaded = 0;

  const InjectCSS = async () => {
    try {
      const cssArray = [
        "/assets/lab/plugins/sidemenu/sidemenu.css",
        "/assets/lab/plugins/bootstrap/css/bootstrap.min.css",
        "/assets/lab/css/style.css",
        "/assets/lab/css/admin-custom.css",
        "/assets/lab/css/custom.css",
        "/assets/lab/css/icons.css",
        "/assets/lab/color-skins/color10.css",
      ];

      stylesNeedToBeLoaded = cssArray.length;

      if (!checkScripts("lab_panel_css")) {
        for (let url of cssArray) {
          await addStyleScript(url, "css", "lab_panel_css");
        }
      } else {
        setLoadingCSS(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const InjectJS = async () => {
    try {
      const masterJs = ["/assets/lab/js/jquery-3.2.1.min.js"];

      const jsArray = [
        "/assets/lab/plugins/bootstrap/js/popper.min.js",
        "/assets/lab/plugins/bootstrap/js/bootstrap.min.js",
        "/assets/lab/plugins/sidemenu/sidemenu.js",
        "/assets/lab/js/admin-custom.js",
      ];
      jsNeedToBeLoaded = jsArray.length;
      if (!checkScripts("lab_panel_js")) {
        for (let url of masterJs) {
          await addStyleScript(url, "js", "lab_panel_js");
        }

        setTimeout(async () => {
          for (let url of jsArray) {
            await addStyleScript(url, "js", "lab_panel_js");
          }
        }, 100);
      } else {
        setLoadingJS(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const styleLoaded = (id) => {
    stylesLoaded++;
    if (stylesNeedToBeLoaded === stylesLoaded) {
      setLoadingCSS(false);
    }
  };

  const jsesLoaded = (id) => {
    jsLoaded++;
    if (jsNeedToBeLoaded === jsLoaded) {
      setLoadingJS(false);
    }
  };

  const addStyleScript = (url, type, package_name) => {
    return new Promise((resolve) => {
      let tag,
        tag_id = sfold(package_name);
      switch (type) {
        case "css":
          tag = document.createElement("link");
          tag.rel = "stylesheet";
          tag.href = url;
          tag.async = true;
          tag.dataset.id = tag_id;
          tag.onload = () => styleLoaded(tag_id);
          break;
        case "js":
          tag = document.createElement("script");
          tag.type = "text/javascript";
          tag.src = url;
          tag.async = true;
          tag.dataset.id = tag_id;
          tag.onload = () => jsesLoaded(tag_id);
          break;
        default:
          return;
      }
      document.head.appendChild(tag);
      return resolve(true);
    });
  };

  React.useMemo(() => {
    if (onlyOnce) {
      InjectCSS();
      InjectJS();
      setOnce(false);
      console.log("Assets injected");
    }
  }, [onlyOnce]);

  return loadingCSS || loadingJS ? (
    <Loader />
  ) : (
    <>
      {children.length ? children.map((child) => <div>{child}</div>) : children}
    </>
  );
};

export default InjectAssets;
