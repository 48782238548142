import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Errors from "views/Notifications/Errors";
import extractNumber from "utils/extractNumber";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import moment from "moment";
import Select from "react-select";
import {
  getInsuranceCardFront,
  getInsuranceCardBack,
  getInsuranceNameList,
} from "actions/front/Employer/Employee";

import * as Constants from "constants/index";
import { statesUs } from "constants/statesUs";

import { uniqueString } from "utils/nodeHelper";

const InsuranceDetails = ({
  errorList,
  formData,
  setFormData,
  currentEmployee,
  getInsuranceCardFront,
  getInsuranceCardBack,
  getInsuranceNameList,
}) => {
  const {
    minDate,
    p_insuranceId,
    p_insuranceName,
    p_state,
    p_group,
    p_effectiveDate,
    p_expirationDate,
    p_insurancePhone_iute164,
    p_insurancePhone_ccode,
    p_plan,
    p_nameOfInsured,
    p_relationToPatient,
    p_dob,
    s_insuranceId,
    s_insuranceName,
    s_state,
    s_group,
    s_effectiveDate,
    s_expirationDate,
    s_insurancePhone_iute164,
    s_insurancePhone_ccode,
    s_plan,
    s_nameOfInsured,
    s_relationToPatient,
    s_dob,
  } = formData;

  const insuranceCommonFieilds = [
    "insuranceId",
    "insuranceName",
    "state",
    "group",
    "effectiveDate",
    "expirationDate",
    "insurancePhone",
    "insurancePhone_iute164",
    "insurancePhone_ccode",
    "plan",
    "nameOfInsured",
    "relationToPatient",
  ];
  // const insuranceFields = []
  const [onlyOnce, setOnce] = useState(true);
  useEffect(() => {
    if (onlyOnce && currentEmployee) {
      setOnce(false);
      const { insurance, insuranceDetails } = currentEmployee;

      let details = {};
      // if (insuranceDetails){
      // details["insuranceDetails"] = insuranceDetails;
      insuranceCommonFieilds.forEach((field) => {
        if (
          insurance &&
          insurance.primaryInsurance &&
          insurance.primaryInsurance[field]
        )
          details["p_" + field] = insurance.primaryInsurance[field];
        if (
          insurance &&
          insurance.secondaryInsurance &&
          insurance.secondaryInsurance[field]
        )
          details["s_" + field] = insurance.secondaryInsurance[field];
      });
      // insuranceFields.forEach(field => {
      //   if (insurance[field])
      //     details[field] = insurance[field]
      // });
      details["insuranceCard_path"] = "";
      if (insurance && insurance.insuranceCard)
        details["insuranceCard_path"] =
          process.env.REACT_APP_SERVER_URL +
          "/documents/patient/" +
          insurance.insuranceCard.fileName;

      if (
        insurance &&
        insurance.primaryInsurance &&
        insurance.primaryInsurance["dob"]
      )
        details["p_dob"] = moment(insurance.primaryInsurance["dob"]).format(
          "YYYY-MM-DD"
        );
      if (
        insurance &&
        insurance.secondaryInsurance &&
        insurance.secondaryInsurance["dob"]
      )
        details["s_dob"] = moment(insurance.secondaryInsurance["dob"]).format(
          "YYYY-MM-DD"
        );
      // }

      setFormData({
        ...formData,
        ...details,
      });
    }
  }, [formData, currentEmployee]);

  const [insuranceNameOptions, setInsuranceNameOptions] = useState([
    { value: "", label: "" },
  ]);
  const [insuranceStateOptions, setInsuranceStateOptions] = useState([
    { value: "", label: "" },
  ]);
  async function getInsuranceList() {
    let insuranceList = await getInsuranceNameList();
    let iList = insuranceList.map(function (name) {
      return { value: name, label: name };
    });
    setInsuranceNameOptions(iList);
  }

  const [onlyOnce1, setOnlyOnce1] = useState(true);
  useEffect(() => {
    if (onlyOnce1) {
      getInsuranceList();
      let sList = statesUs.map(function (state) {
        return { value: state, label: state };
      });
      setInsuranceStateOptions(sList);
      setOnlyOnce1(false);
    }
  }, [setOnlyOnce1, getInsuranceList]);

  const [pMinDate, setPMinDate] = useState();
  const [sMinDate, setSMinDate] = useState();

  const onChange = (e) => {
    switch (e.target.name) {
      case "insuranceDetails":
        setFormData({ ...formData, [e.target.name]: e.target.checked });
        break;
      case "p_insuranceCardFront":
      case "p_insuranceCardBack":
      case "s_insuranceCardFront":
      case "s_insuranceCardBack":
        setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        break;
      case "p_effectiveDate":
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setPMinDate(e.target.value);
        break;
      case "s_effectiveDate":
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setSMinDate(e.target.value);
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [p_insuranceNameSelectedOption, setPInsuranceName] = useState("");
  const [s_insuranceNameSelectedOption, setSInsuranceName] = useState("");
  const [p_insuranceStateSelectedOption, setPInsuranceState] = useState("");
  const [s_insuranceStateSelectedOption, setSInsuranceState] = useState("");

  const handleSelect = (name, data) => {
    switch (name) {
      case "p_insuranceName":
        setPInsuranceName(data);
        break;
      case "s_insuranceName":
        setSInsuranceName(data);
        break;
      case "p_state":
        setPInsuranceState(data);
        break;
      case "s_state":
        setSInsuranceState(data);
        break;
    }
    if (data.value === "Other") {
      setFormData({
        ...formData,
        [name]: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: data.value,
      });
    }
  };

  const onPPhoneChange = (number) => {
    const phone = extractNumber(number, p_insurancePhone_ccode);
    setFormData((form) => ({
      ...form,
      p_insurancePhone: phone,
      p_insurancePhone_iute164: number || p_insurancePhone_ccode,
    }));
  };
  const onPCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      p_insurancePhone_ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };
  const onSPhoneChange = (number) => {
    const phone = extractNumber(number, s_insurancePhone_ccode);
    setFormData((form) => ({
      ...form,
      s_insurancePhone: phone,
      s_insurancePhone_iute164: number || s_insurancePhone_ccode,
    }));
  };
  const onSCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      s_insurancePhone_ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  useMemo(() => {
    setValue(setPInsuranceName, insuranceNameOptions, p_insuranceName);
    setValue(setSInsuranceName, insuranceNameOptions, s_insuranceName);
    setValue(setPInsuranceState, insuranceStateOptions, p_state);
    setValue(setSInsuranceState, insuranceStateOptions, s_state);
  }, [insuranceStateOptions, insuranceNameOptions]);

  function setValue(func, list, value) {
    if (value) {
      let selectedOption = list.filter((opt) => opt.value === value);
      if (!selectedOption.length) {
        selectedOption = list.filter((opt) => opt.value === "Other");
      }
      func(selectedOption[0]);
    }
  }

  useMemo(() => {
    if (currentEmployee && currentEmployee.insurance) {
      if (
        currentEmployee.insurance.primaryInsurance &&
        currentEmployee.insurance.primaryInsurance.insuranceName
      ) {
        setValue(
          setPInsuranceName,
          insuranceNameOptions,
          currentEmployee.insurance.primaryInsurance.insuranceName
        );
      }
      if (
        currentEmployee.insurance.secondaryInsurance &&
        currentEmployee.insurance.secondaryInsurance.insuranceName
      ) {
        setValue(
          setSInsuranceName,
          insuranceNameOptions,
          currentEmployee.insurance.secondaryInsurance.insuranceName
        );
      }
    }
  }, [currentEmployee]);

  useMemo(() => {
    if (currentEmployee && currentEmployee.insurance) {
      if (
        currentEmployee.insurance.primaryInsurance &&
        currentEmployee.insurance.primaryInsurance.state
      ) {
        setValue(
          setPInsuranceState,
          insuranceStateOptions,
          currentEmployee.insurance.primaryInsurance.state
        );
      }
      if (
        currentEmployee.insurance.secondaryInsurance &&
        currentEmployee.insurance.secondaryInsurance.state
      ) {
        setValue(
          setSInsuranceState,
          insuranceStateOptions,
          currentEmployee.insurance.secondaryInsurance.state
        );
      }
    }
  }, [currentEmployee]);

  const [pInsuranceCardFrontPreview, setPInsuranceCardFrontPreview] =
    useState("");
  useMemo(() => {
    if (currentEmployee && currentEmployee.insurance) {
      let primaryInsurance =
        currentEmployee.insurance && currentEmployee.insurance.primaryInsurance
          ? currentEmployee.insurance.primaryInsurance
          : {};
      if (
        primaryInsurance.insuranceCardFront &&
        primaryInsurance.insuranceCardFront.path
      ) {
        getInsuranceCardFront(currentEmployee._id, "primary").then((blob) => {
          if (!blob) {
            setPInsuranceCardFrontPreview("-");
            return;
          }

          const fileURL = window.URL.createObjectURL(
            new Blob([blob], {
              type: primaryInsurance.insuranceCardFront.fileType,
            })
          );

          if (
            primaryInsurance.insuranceCardFront.fileType.indexOf("image/") !==
            -1
          ) {
            const imageFrontPreview = (
              <div
                className="image-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    primaryInsurance.insuranceCardFront.fileName
                  )
                }
              >
                <img src={fileURL}></img>
              </div>
            );
            setPInsuranceCardFrontPreview(imageFrontPreview);
          } else {
            const pdfFrontPreview = (
              <div
                className="document-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    primaryInsurance.insuranceCardFront.fileName
                  )
                }
              >
                <i class="fa fa-file"></i>
                <span className="preview-filename">
                  {primaryInsurance.insuranceCardFront.fileName}
                </span>
                <small>Click to download</small>
              </div>
            );
            setPInsuranceCardFrontPreview(pdfFrontPreview);
          }
        });
      }
    }
  }, [currentEmployee]);

  const [pInsuranceCardBackPreview, setPInsuranceCardBackPreview] =
    useState("");
  useMemo(() => {
    if (currentEmployee && currentEmployee.insurance) {
      let primaryInsurance =
        currentEmployee.insurance && currentEmployee.insurance.primaryInsurance
          ? currentEmployee.insurance.primaryInsurance
          : {};
      if (
        primaryInsurance.insuranceCardBack &&
        primaryInsurance.insuranceCardBack.path
      ) {
        getInsuranceCardBack(currentEmployee._id, "primary").then((blob) => {
          if (!blob) {
            setPInsuranceCardBackPreview("-");
            return;
          }

          const fileURL = window.URL.createObjectURL(
            new Blob([blob], {
              type: primaryInsurance.insuranceCardBack.fileType,
            })
          );

          if (
            primaryInsurance.insuranceCardBack.fileType.indexOf("image/") !== -1
          ) {
            const imageFrontPreview = (
              <div
                className="image-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    primaryInsurance.insuranceCardBack.fileName
                  )
                }
              >
                <img src={fileURL}></img>
              </div>
            );
            setPInsuranceCardBackPreview(imageFrontPreview);
          } else {
            const pdfFrontPreview = (
              <div
                className="document-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    primaryInsurance.insuranceCardBack.fileName
                  )
                }
              >
                <i class="fa fa-file"></i>
                <span className="preview-filename">
                  {primaryInsurance.insuranceCardBack.fileName}
                </span>
                <small>Click to download</small>
              </div>
            );
            setPInsuranceCardBackPreview(pdfFrontPreview);
          }
        });
      }
    }
  }, [currentEmployee]);

  const [sInsuranceCardFrontPreview, setSInsuranceCardFrontPreview] =
    useState("");
  useMemo(() => {
    if (currentEmployee && currentEmployee.insurance) {
      let secondaryInsurance =
        currentEmployee.insurance &&
        currentEmployee.insurance.secondaryInsurance
          ? currentEmployee.insurance.secondaryInsurance
          : {};
      if (
        secondaryInsurance.insuranceCardFront &&
        secondaryInsurance.insuranceCardFront.path
      ) {
        getInsuranceCardFront(currentEmployee._id, "secondary").then((blob) => {
          if (!blob) {
            setSInsuranceCardFrontPreview("-");
            return;
          }

          const fileURL = window.URL.createObjectURL(
            new Blob([blob], {
              type: secondaryInsurance.insuranceCardFront.fileType,
            })
          );

          if (
            secondaryInsurance.insuranceCardFront.fileType.indexOf("image/") !==
            -1
          ) {
            const imageFrontPreview = (
              <div
                className="image-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    secondaryInsurance.insuranceCardFront.fileName
                  )
                }
              >
                <img src={fileURL}></img>
              </div>
            );
            setSInsuranceCardFrontPreview(imageFrontPreview);
          } else {
            const pdfFrontPreview = (
              <div
                className="document-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    secondaryInsurance.insuranceCardFront.fileName
                  )
                }
              >
                <i class="fa fa-file"></i>
                <span className="preview-filename">
                  {secondaryInsurance.insuranceCardFront.fileName}
                </span>
                <small>Click to download</small>
              </div>
            );
            setSInsuranceCardFrontPreview(pdfFrontPreview);
          }
        });
      }
    }
  }, [currentEmployee]);

  const [sInsuranceCardBackPreview, setSInsuranceCardBackPreview] =
    useState("");
  useMemo(() => {
    if (currentEmployee && currentEmployee.insurance) {
      let secondaryInsurance =
        currentEmployee.insurance &&
        currentEmployee.insurance.secondaryInsurance
          ? currentEmployee.insurance.secondaryInsurance
          : {};
      if (
        secondaryInsurance.insuranceCardBack &&
        secondaryInsurance.insuranceCardBack.path
      ) {
        getInsuranceCardBack(currentEmployee._id, "secondary").then((blob) => {
          if (!blob) {
            setSInsuranceCardBackPreview("-");
            return;
          }

          const fileURL = window.URL.createObjectURL(
            new Blob([blob], {
              type: secondaryInsurance.insuranceCardBack.fileType,
            })
          );

          if (
            secondaryInsurance.insuranceCardBack.fileType.indexOf("image/") !==
            -1
          ) {
            const imageFrontPreview = (
              <div
                className="image-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    secondaryInsurance.insuranceCardBack.fileName
                  )
                }
              >
                <img src={fileURL}></img>
              </div>
            );
            setSInsuranceCardBackPreview(imageFrontPreview);
          } else {
            const pdfFrontPreview = (
              <div
                className="document-preview"
                title="Click to download"
                onClick={() =>
                  downloadCard(
                    fileURL,
                    secondaryInsurance.insuranceCardBack.fileName
                  )
                }
              >
                <i class="fa fa-file"></i>
                <span className="preview-filename">
                  {secondaryInsurance.insuranceCardBack.fileName}
                </span>
                <small>Click to download</small>
              </div>
            );
            setSInsuranceCardBackPreview(pdfFrontPreview);
          }
        });
      }
    }
  }, [currentEmployee]);

  const downloadCard = (blobFileURL, fileName) => {
    // create and remove link to download pdf
    const link = document.createElement("a");
    link.href = blobFileURL;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title">
          <span className=" text-dark">Insurance Information</span>
        </h4>
      </CardHeader>

      <CardBody>
        <Row>
          <Col lg="6" md="6" sm="12">
            <h5 className="in-sub-heading">
              <Label>
                <span className="text-dark">Primary Insurance</span>
              </Label>
            </h5>

            <Row>
              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_insuranceCardFront">
                    Insurance card FRONT (image only)
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="p_insuranceCardFront"
                    onChange={(e) => onChange(e)}
                  />
                  <Errors
                    current_key="p_insuranceCardFront"
                    key="p_insuranceCardFront"
                  />

                  <div className="card-preview">
                    {pInsuranceCardFrontPreview}
                  </div>
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_insuranceCardBack">
                    Insurance card BACK (image only)
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="p_insuranceCardBack"
                    onChange={(e) => onChange(e)}
                  />
                  <Errors
                    current_key="p_insuranceCardBack"
                    key="p_insuranceCardBack"
                  />

                  <div className="card-preview">
                    {pInsuranceCardBackPreview}
                  </div>
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_insuranceId">Insurance ID #</Label>
                  <Input
                    type="text"
                    id="p_insuranceId"
                    name="p_insuranceId"
                    maxLength="50"
                    value={p_insuranceId}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.p_insuranceId ? true : false}
                  />
                  <Errors current_key="p_insuranceId" key="p_insuranceId" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_insuranceName"> Insurance Name </Label>
                  <Select
                    cacheOptions
                    id="p_insuranceName"
                    name="p_insuranceName"
                    options={[...insuranceNameOptions]}
                    value={p_insuranceNameSelectedOption}
                    onChange={(e) => handleSelect("p_insuranceName", e)}
                    invalid={errorList.p_insuranceName ? true : false}
                  />
                  <Errors current_key="p_insuranceName" key="p_insuranceName" />
                </FormGroup>
              </Col>
              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_state">State </Label>
                  <Select
                    cacheOptions
                    id="p_state"
                    name="p_state"
                    options={[...insuranceStateOptions]}
                    value={p_insuranceStateSelectedOption}
                    onChange={(e) => handleSelect("p_state", e)}
                    invalid={errorList.p_state ? true : false}
                  />
                  <Errors current_key="p_state" key="p_state" />
                </FormGroup>
              </Col>
              {p_insuranceNameSelectedOption &&
                p_insuranceNameSelectedOption.value === "Other" && (
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <Label htmlFor="p_insuranceName">
                        Other Insurance Name *
                      </Label>
                      <Input
                        type="text"
                        id="p_insuranceName"
                        name="p_insuranceName"
                        maxLength="50"
                        value={p_insuranceName}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.p_insuranceName ? true : false}
                      />
                      <Errors
                        current_key="p_insuranceName"
                        key="p_insuranceName"
                      />
                    </FormGroup>
                  </Col>
                )}
              {p_insuranceStateSelectedOption &&
                p_insuranceStateSelectedOption.value === "Other" && (
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <Label htmlFor="p_state">Other State Name *</Label>
                      <Input
                        type="text"
                        id="p_state"
                        name="p_state"
                        maxLength="50"
                        value={p_state}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.p_state ? true : false}
                      />
                      <Errors current_key="p_state" key="p_state" />
                    </FormGroup>
                  </Col>
                )}
              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_group">Group #</Label>
                  <Input
                    type="text"
                    id="p_group"
                    name="p_group"
                    maxLength="50"
                    value={p_group}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.p_group ? true : false}
                  />
                  <Errors current_key="p_group" key="p_group" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_effectiveDate">Effective Date</Label>
                  <Input
                    type="Date"
                    id="p_effectiveDate"
                    name="p_effectiveDate"
                    value={p_effectiveDate}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="p_effectiveDate" key="p_effectiveDate" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_expirationDate">Expiration Date</Label>
                  <Input
                    type="Date"
                    id="p_expirationDate"
                    name="p_expirationDate"
                    value={p_expirationDate}
                    min={pMinDate ? pMinDate : undefined}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors
                    current_key="p_expirationDate"
                    key="p_expirationDate"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="p_insurancePhone">
                    Insurance Phone
                  </Label>
                  <PhoneInput
                    className="hk_custom_sag"
                    autoComplete={uniqueString()}
                    international={true}
                    defaultCountry="US"
                    countryOptionsOrder={["US", "|", "..."]}
                    value={p_insurancePhone_iute164}
                    name="p_insurancePhone"
                    addInternationalOption={false}
                    onChange={onPPhoneChange}
                    onCountryChange={onPCountryChange}
                  />
                  <Errors
                    current_key="p_insurancePhone"
                    key="p_insurancePhone"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_plan">Insurance Plan</Label>
                  <Input
                    type="text"
                    id="p_plan"
                    name="p_plan"
                    maxLength="50"
                    value={p_plan}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.p_plan ? true : false}
                  />
                  <Errors current_key="p_plan" key="p_plan" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_nameOfInsured">Name of insured</Label>
                  <Input
                    type="text"
                    id="p_nameOfInsured"
                    name="p_nameOfInsured"
                    maxLength="50"
                    value={p_nameOfInsured}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.p_nameOfInsured ? true : false}
                  />
                  <Errors current_key="p_nameOfInsured" key="p_nameOfInsured" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="p_relationToPatient">
                    Relationship to patient
                  </Label>
                  <Input
                    type="text"
                    id="p_relationToPatient"
                    name="p_relationToPatient"
                    maxLength="50"
                    value={p_relationToPatient}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.p_relationToPatient ? true : false}
                  />
                  <Errors
                    current_key="p_relationToPatient"
                    key="p_relationToPatient"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label> Date of Birth </Label>
                  <Input
                    type="Date"
                    id="p_dob"
                    name="p_dob"
                    value={p_dob}
                    max={minDate}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="p_dob" key="p_dob" />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col lg="6" md="6" sm="12">
            <h5 className="in-sub-heading">
              <Label>
                <span className="text-dark">Secondary Insurance</span>
              </Label>
            </h5>

            <Row>
              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_insuranceCardFront">
                    Insurance card FRONT (image only)
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="s_insuranceCardFront"
                    onChange={(e) => onChange(e)}
                  />
                  <Errors
                    current_key="s_insuranceCardFront"
                    key="s_insuranceCardFront"
                  />

                  <div className="card-preview">
                    {sInsuranceCardFrontPreview}
                  </div>
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_insuranceCardBack">
                    Insurance card BACK (image only)
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    name="s_insuranceCardBack"
                    onChange={(e) => onChange(e)}
                  />
                  <Errors
                    current_key="s_insuranceCardBack"
                    key="s_insuranceCardBack"
                  />

                  <div className="card-preview">
                    {sInsuranceCardBackPreview}
                  </div>
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_insuranceId">Insurance ID #</Label>
                  <Input
                    type="text"
                    id="s_insuranceId"
                    name="s_insuranceId"
                    maxLength="50"
                    value={s_insuranceId}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.s_insuranceId ? true : false}
                  />
                  <Errors current_key="s_insuranceId" key="s_insuranceId" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_insuranceName"> Insurance Name </Label>
                  <Select
                    cacheOptions
                    id="s_insuranceName"
                    name="s_insuranceName"
                    options={[...insuranceNameOptions]}
                    value={s_insuranceNameSelectedOption}
                    onChange={(e) => handleSelect("s_insuranceName", e)}
                    invalid={errorList.s_insuranceName ? true : false}
                  />
                  <Errors current_key="s_insuranceName" key="s_insuranceName" />
                </FormGroup>
              </Col>
              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_state">State </Label>
                  <Select
                    cacheOptions
                    id="s_state"
                    name="s_state"
                    options={[...insuranceStateOptions]}
                    value={s_insuranceStateSelectedOption}
                    onChange={(e) => handleSelect("s_state", e)}
                    invalid={errorList.s_state ? true : false}
                  />
                  <Errors current_key="s_state" key="s_state" />
                </FormGroup>
              </Col>

              {s_insuranceNameSelectedOption &&
                s_insuranceNameSelectedOption.value === "Other" && (
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <Label htmlFor="s_insuranceName">
                        Other Insurance Name *
                      </Label>
                      <Input
                        type="text"
                        id="s_insuranceName"
                        name="s_insuranceName"
                        maxLength="50"
                        value={s_insuranceName}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.s_insuranceName ? true : false}
                      />
                      <Errors
                        current_key="s_insuranceName"
                        key="s_insuranceName"
                      />
                    </FormGroup>
                  </Col>
                )}
              {s_insuranceStateSelectedOption &&
                s_insuranceStateSelectedOption.value === "Other" && (
                  <Col sm="12" md="12" lg="6">
                    <FormGroup>
                      <Label htmlFor="s_state">Other State Name *</Label>
                      <Input
                        type="text"
                        id="s_state"
                        name="s_state"
                        maxLength="50"
                        value={s_state}
                        autoComplete={uniqueString()}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.s_state ? true : false}
                      />
                      <Errors current_key="s_state" key="s_state" />
                    </FormGroup>
                  </Col>
                )}
              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_group">Group #</Label>
                  <Input
                    type="text"
                    id="s_group"
                    name="s_group"
                    maxLength="50"
                    value={s_group}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.s_group ? true : false}
                  />
                  <Errors current_key="s_group" key="s_group" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_effectiveDate">Effective Date</Label>
                  <Input
                    type="Date"
                    id="s_effectiveDate"
                    name="s_effectiveDate"
                    value={s_effectiveDate}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="s_effectiveDate" key="s_effectiveDate" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_expirationDate">Expiration Date</Label>
                  <Input
                    type="Date"
                    id="s_expirationDate"
                    name="s_expirationDate"
                    value={s_expirationDate}
                    min={sMinDate ? sMinDate : undefined}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors
                    current_key="s_expirationDate"
                    key="s_expirationDate"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label className="form-label" htmlFor="s_insurancePhone">
                    Insurance Phone
                  </Label>
                  <PhoneInput
                    className="hk_custom_sag"
                    autoComplete={uniqueString()}
                    international={true}
                    defaultCountry="US"
                    countryOptionsOrder={["US", "|", "..."]}
                    value={s_insurancePhone_iute164}
                    name="s_insurancePhone"
                    addInternationalOption={false}
                    onChange={onSPhoneChange}
                    onCountryChange={onSCountryChange}
                  />
                  <Errors
                    current_key="s_insurancePhone"
                    key="s_insurancePhone"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_plan">Insurance Plan</Label>
                  <Input
                    type="text"
                    id="s_plan"
                    name="s_plan"
                    maxLength="50"
                    value={s_plan}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.s_plan ? true : false}
                  />
                  <Errors current_key="s_plan" key="s_plan" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_nameOfInsured">Name of insured</Label>
                  <Input
                    type="text"
                    id="s_nameOfInsured"
                    name="s_nameOfInsured"
                    maxLength="50"
                    value={s_nameOfInsured}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.s_nameOfInsured ? true : false}
                  />
                  <Errors current_key="s_nameOfInsured" key="s_nameOfInsured" />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label htmlFor="s_relationToPatient">
                    Relationship to patient
                  </Label>
                  <Input
                    type="text"
                    id="s_relationToPatient"
                    name="s_relationToPatient"
                    maxLength="50"
                    value={s_relationToPatient}
                    autoComplete={uniqueString()}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.s_relationToPatient ? true : false}
                  />
                  <Errors
                    current_key="s_relationToPatient"
                    key="s_relationToPatient"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" md="12" lg="6">
                <FormGroup>
                  <Label> Date of Birth </Label>
                  <Input
                    type="Date"
                    id="s_dob"
                    name="s_dob"
                    value={s_dob}
                    max={minDate}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="s_dob" key="s_dob" />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

InsuranceDetails.propTypes = {
  errorList: PropTypes.object.isRequired,
  getInsuranceCardFront: PropTypes.func.isRequired,
  getInsuranceCardBack: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  currentEmployee: state.Employee.currentEmployee,
});

export default connect(mapStateToProps, {
  getInsuranceCardFront,
  getInsuranceCardBack,
  getInsuranceNameList,
})(InsuranceDetails);
