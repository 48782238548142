import {
  FRONTPHYSICIAN_ERROR,
  FRONTPHYSICIAN_UPDATED,
  FRONTPHYSICIAN_GET_BY_ID,
  FRONTPHYSICIAN_LOADING_ON_SUBMIT,
  FRONTPHYSICIAN_RESET,
  FRONTPHYSICIAN_LOAD_PAGE,
} from "actions/types";

const initialState = {
  currentPhysician: null,
  // authId: false,
  loadingPhysician: true,
  loadingListData: true,
  performDelete: false,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FRONTPHYSICIAN_RESET:
      return {
        ...initialState,
        loadingPhysician: state.loadingPhysician,
      };
    case FRONTPHYSICIAN_LOAD_PAGE:
      return {
        ...state,
        loadingPhysician: false,
      };
    case FRONTPHYSICIAN_UPDATED:
      return {
        ...state,
        currentPhysician: payload,
        sortingParams: initialState.sortingParams,
        loadingPhysician: false,
      };
    case FRONTPHYSICIAN_ERROR:
      return {
        ...state,
        error: payload,
        loadingPhysician: false,
        loadingPhysicianList: false,
        loadingListData: false,
        performDelete: false,
      };
    case FRONTPHYSICIAN_GET_BY_ID:
      return {
        ...state,
        currentPhysician: payload,
        loadingPhysician: false,
      };
    case FRONTPHYSICIAN_LOADING_ON_SUBMIT:
      return {
        ...state,
        loadingPhysician: true,
      };
    default:
      return state;
  }
}
