import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "actions/alert";
import {
  setPassword as resetPassword,
  setPasswordError,
  loadPage,
  loadingOnLoginSubmit,
} from "actions/auth";
import { sfold, checkScripts } from "utils/sFold";
import Errors from "views/Notifications/Errors";
import Alert from "views/Notifications/Alert";
import PropTypes from "prop-types";
import Spinner from "views/Spinner";
import Img from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";
import { Helmet } from "react-helmet";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import LoginLayout from "./LoginLayout";

const SetPassword = ({
  setAlert,
  setPasswordError,
  resetPassword,
  loadPage,
  history,
  authId,
  errorList,
  match,
}) => {
  const [onlyOnce, setOnce] = useState(true);

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);

  const { password, confirm_password } = formData;

  useMemo(() => {
    if (onlyOnce) {
      loadPage();
      setOnce(false);
    }
  }, [match.params.token, history, loadPage]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      setPasswordError("Passwords do not match", "confirm_password");
      setAlert(
        "Errors! Please correct the following errors and submit again.",
        "danger"
      );
    } else {
      setLoading(true);
      resetPassword(password, authId, match.params.token, history).then(
        (res) => {
          if (res && res.status === true) {
            setLoading(false);
          }
        }
      );
    }
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Set password - {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <LoginLayout>
        <h3>Set Password</h3>
        <Alert />
        <Form onSubmit={(e) => onSubmit(e)}>
          <FormGroup>
            <Label className="form-label text-dark">Password*</Label>
            <Input
              type="password"
              autoComplete="password"
              name="password"
              minLength="8"
              value={password || ""}
              onChange={(e) => onChange(e)}
              invalid={errorList.password ? true : false}
            />
            <Errors current_key="password" key="password" />
          </FormGroup>
          <FormGroup>
            <Label className="form-label text-dark">Confirm Password*</Label>
            <Input
              type="password"
              autoComplete="confirm_password"
              name="confirm_password"
              minLength="8"
              value={confirm_password || ""}
              onChange={(e) => onChange(e)}
              invalid={errorList.confirm_password ? true : false}
            />
            <Errors current_key="confirm_password" key="confirm_password" />
          </FormGroup>
          <div className="form-footer mt-2">
            <button className="btn btn-primary btn-inline float-right">
              Set Password{" "}
            </button>
          </div>
        </Form>
      </LoginLayout>
    </>
  );
};

SetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  setPasswordError: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  loadingOnLoginSubmit: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  authId: state.auth.user._id,
});

export default connect(mapStateToProps, {
  setAlert,
  setPasswordError,
  resetPassword,
  loadPage,
  loadingOnLoginSubmit,
})(SetPassword);
