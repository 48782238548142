import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import Img from "react-image";
import LogoSvg from "assets/iLabDx-Logo.png";

const Dashboard = ({ history, isAuthenticated, user }) => {
  let [appLink, setAppLink] = useState("");
  const getAppVersion = async () => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const res = await axios.get(`/api/getAppVersion`, config);
    if (res && res.data && res.data.response) {
      console.log("Download link set to -> ", res.data.response.androidAppUrl);
      setAppLink(res.data.response.androidAppUrl);
    }
  };

  useMemo(async () => {
    getAppVersion();
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      history.push("/login");
    }
  }, [isAuthenticated, user]);

  return (
    <div className="landing-inner landing-page">
      <div
        className="text-center"
        style={{ width: "300px", marginBottom: "50px" }}
      >
        <Img alt="" src={LogoSvg} className="w-70" style={{ width: "260px" }} />
      </div>

      <div className="buttons">
        <Link
          to="/login"
          className="btn btn-light"
          style={{ padding: "5px 15px" }}
        >
          Organization/Lab/Physician/Staff Login
        </Link>
        {/* <Link to="/register" className="btn btn-light" style={{ padding: '8px 20px' }}>
          Organization Sign Up
        </Link> */}

        <a
          className="btn"
          href={appLink}
          download
          style={{ padding: "5px 20px", fontSize: "1rem" }}
        >
          <i className="fa fa-android mr-8" style={{ fontSize: "1.1rem" }}></i>
          <span>Download Android App</span>
        </a>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Dashboard);
