import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "views/Spinner";
import {
  EMPLOYER_ROLE,
  LAB_ROLE,
  PHYSICIAN_ROLE,
  STAFF_ROLE,
  PHLEBOTOMIST_ROLE,
  NURSE_ROLE,
} from "constants/index";

const PublicRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  authId,
  restricted,
  publicPage,
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated && !loading && !restricted) {
          // console.log("transferred volume");
          return <Component {...props} />;
        } else {
          if (isAuthenticated !== null) {
            if (user !== null) {
              // var isAdmin = false;
              var isEmployer = false;
              var isLab = false;
              var isPhysician = false;
              var isStaff = false;

              switch (user.role) {
                case EMPLOYER_ROLE:
                  isEmployer = true;
                  break;
                case LAB_ROLE:
                  isLab = true;
                  break;
                case PHYSICIAN_ROLE:
                  isPhysician = true;
                  break;
                case STAFF_ROLE:
                case PHLEBOTOMIST_ROLE:
                case NURSE_ROLE:
                  isStaff = true;
                  break;
                default:
                  isEmployer = true;
              }
              if (user && user.setPassword) {
                return <Component {...props} />;
              }

              if (publicPage) {
                return <Component {...props} />;
              }

              if (isEmployer) {
                return <Redirect to="/organization" />;
              } else if (isLab) {
                return <Redirect to="/lab" />;
              } else if (isPhysician) {
                return <Redirect to="/physician" />;
              } else if (isStaff) {
                return <Redirect to="/staff/dashboard" />;
              } else {
                return <Redirect to="/" />;
              }
            }

            return <Component {...props} />;
          }

          return <Spinner />;
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  authId: state.auth.authId,
});

export default connect(mapStateToProps)(PublicRoute);
