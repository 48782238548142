import { GoogleApiWrapper } from 'google-maps-react';
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Label,
  FormGroup,
} from "reactstrap";
import Errors from "views/Notifications/Errors";

const GMaps = ({
  onChange,
  google,
  location,
  placeholder,
  types,
  country,
  label,
  isRequired,
  isDisabled,
  autoComplete,
  setFormData,
  removeErrors,
  setErrors
}) => {
  if (isRequired === undefined) isRequired = false;
  const autocompleteEl = useRef(null);
  let subscribe = true;

  country = [
    "us",
    "pr",
    "vi",
    "gu",
    "mp"
  ];
  if (autoComplete === undefined) autoComplete = "off";

  const locationGenerate = () => {
    var defaultBounds = null;
    try {
      defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(location.south, location.west),
        new google.maps.LatLng(location.north, location.east),
      );
    }
    catch (err) {
      console.log(err);
    }
    // console.log(defaultBounds);
    return defaultBounds;
  }

  const renderAutoComplete = () => {
    if (!google || !subscribe) return;
    const bounds = location && locationGenerate(location);
    const autocomplete = new google.maps.places.Autocomplete(autocompleteEl.current, {
      bounds,
    });
    autocomplete.setFields(["formatted_address", "address_components", "geometry", "name", "place_id", "types"]);
    bounds && autocomplete.setOptions({ strictBounds: true });
    types && autocomplete.setTypes([types]);
    country && autocomplete.setComponentRestrictions({ country });
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (typeof onChange === 'function') {
        onChange(place);
      }

      onChangePlace(place);
    });
  }

  const onInput = (e) => {
    e.preventDefault();
    if (subscribe) {
      const value = e.target.value;
      if (!value) {
        const locationObj = {
          place_id: "",
          name: "",
          address_components: [],
          geometry: {
            location: {
              lat: "",
              lng: "",
            },
            viewport: {
              south: "",
              west: "",
              north: "",
              east: "",
            }
          }
        };
        if (typeof onChange === 'function') {
          onChange(locationObj);
        }

        onChangePlace(locationObj);
      }
    }
  }

  useEffect(() => {
    const asyncFunc = async () => subscribe && autocompleteEl.current && renderAutoComplete();
    asyncFunc();
    return () => {
      subscribe = false;
    }
  }, [autocompleteEl.current]);

  /*
  * On change handler
  */
  const onChangePlace = location => {
    if (typeof setFormData !== 'function' || typeof removeErrors !== 'function' || typeof setErrors !== 'function') return;

    const place_id = location.place_id;
    const city_name = location.name;
    const addresses = location.address_components;
    const formatted_address = location.formatted_address;
    let geometry = "";

    if (place_id) {
      geometry = location.geometry.viewport;
    }

    let state = '';
    let country = '';
    let short = '';
    let stateAbbr = '';
    const a_city_placeholder = city_name ? formatted_address : "";
    setFormData((form) => (
      {
        ...form,
        a_city: {
          formatted_address,
          city_name,
          place_id,
          state,
          country,
          short,
          stateAbbr,
          location: geometry,
        },
        a_city_placeholder,
      }));

    if (!place_id) return;
    if (location.types.indexOf("locality") !== -1 || location.types.indexOf("administrative_area_level_3") !== -1) {
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'administrative_area_level_1':
            state = e.long_name;
            stateAbbr = e.short_name;
            break;
          case 'country':
            country = e.long_name;
            short = e.short_name;
            break;
        }
      });
      const a_city = {
        formatted_address,
        city_name,
        state,
        country,
        location: geometry,
        short,
        stateAbbr,
        place_id,
      };

      setFormData((form) => ({
        ...form,
        a_city,
      }));
      removeErrors();
    } else {
      const errors = [];
      errors.push({
        param: "g_location_picker",
        msg: "Please select a valid city",
      });
      setErrors(errors);
    }
  }

  return (
    <>
      <FormGroup>
        <Label htmlFor="g_location_picker" className="form-label">
          {label} {isRequired ? <span>*</span> : ""}
        </Label>
        <input
          className="form-control gmap-citypicker"
          type="text"
          onChange={onInput}
          placeholder={placeholder === "" ? "" : placeholder}
          ref={autocompleteEl}
          disabled={isDisabled}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault(); e.target.autocomplete = autoComplete;
          }}
          id="g_location_picker"
          name="g_location_picker"
          onFocus={(e) => { e.target.autocomplete = autoComplete; }}
        />
        <Errors current_key="g_location_picker" key="g_location_picker" />
      </FormGroup>
    </>
  );
}

GMaps.propTypes = {
  google: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  google: state.google,
  map: state.map,
});

export default connect(mapStateToProps, null)(GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API
})(GMaps));
