import React from "react";
import { useDispatch } from "react-redux";

import Alert from "views/Notifications/Alert";
import * as Constants from "constants/index";
import Errors from "views/Notifications/Errors";

import {
  CardHeader,
  CardBody,
  Col,
  Form,
  Input,
  Button,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import { ENABLE_RESEND_OTP_OPTION } from "../../../../actions/types";

const TwoFactorForm = ({
  formData,
  setFormData,
  errorList,
  login,
  enableResendOTPOption,
  loadAuthPage,
  history,
  showResendOTPOptionWithTimer,
  showResendButton,
  setShowResendButton,
  textToShowForResendTimer,
}) => {
  const dispatch = useDispatch();

  const requestResendOTP = () => {
    setShowResendButton(false);

    const submitData = {
      email: formData.email,
      password: formData.password,
      askForTwoFactor: true,
      requestResendOTP: true,
      userType: Constants.EMPLOYER_ROLE,
    };

    console.log("Request resend OTP. submitData", submitData);
    login(submitData, history).then((res) => {
      console.log("two factor asked.", "\n", "Resend OTP option enabled.");
      dispatch({
        type: ENABLE_RESEND_OTP_OPTION,
      });

      showResendOTPOptionWithTimer();
    });
  };

  const { otp } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitTwoFactor = (e) => {
    e.preventDefault();
    loadAuthPage(history);
    login(formData, history);
  };

  return (
    <Form onSubmit={(e) => onSubmitTwoFactor(e)}>
      <h3>Two factor authentication</h3>
      <Alert />
      <FormGroup>
        <Label className="form-label text-dark">One time password*</Label>
        <Input
          type="otp"
          placeholder="Enter OTP"
          name="otp"
          maxLength="50"
          value={otp}
          onChange={(e) => onChange(e)}
          required
          autoComplete="off"
          invalid={errorList.otp ? true : false}
        />
        <Errors current_key="otp" key="otp" />
      </FormGroup>
      <Row>
        <Col xs="4">
          <div className="form-footer mt-2">
            <button className="btn btn-primary float-left"> Verify </button>
          </div>
        </Col>
        {enableResendOTPOption && (
          <Col xs="8">
            {showResendButton === true ? (
              <Button
                color="link"
                className="px-0 float-right"
                onClick={(e) => requestResendOTP()}
              >
                Resend OTP
              </Button>
            ) : (
              <>
                {textToShowForResendTimer ? (
                  <span
                    style={{ fontSize: "small" }}
                    color="link"
                    className="px-0 btn"
                  >
                    {textToShowForResendTimer}
                  </span>
                ) : null}
              </>
            )}
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default TwoFactorForm;
